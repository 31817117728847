import React, {Component, createRef} from "react";
import {connect} from "react-redux";
import {Field, FieldsManager} from "../../data/services/fields";
import FieldText from "../field-text";
import Resources from "../../data/services/resources";
import LocalStorage from "../../util/localStorage";
import {createResource, updateResource} from "../../data/actions/resource";
import FieldSelectSearch from "../field-select-search";
import Dropzone from "react-dropzone";
import Env from "../../util/env";
import {
    classNames,
    fillFieldsFromData,
    SET_QUESTION_TYPE_IMAGE_ID,
    SET_QUESTION_TYPE_NOT_APPLICABLE_ID,
    SET_QUESTION_TYPE_VIDEO_ID,
    SET_QUESTION_TYPES,
} from "../../util/util";
import FieldDropdownSelect from "../field-dropdown-select";
import axios from "axios";
import {processResponse} from "../../data/services/api-util";

class SetDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            files: [],
            DeletedImages: !!this.props.selectedItem ? new Array(...this.props.selectedItem.QuestionContent) : [],
            clientImages: this.getImages(),
            errorMessage: false,
            canSubmit: false
        };

        this.dropzoneRef = createRef();
    }

    componentDidMount() {
        this.setState({
            fields: this.getFields(this.props.selectedItem),
            clientImages: this.getImages(),
            DeletedImages: !!this.props.selectedItem ? new Array(...this.props.selectedItem.QuestionContent) : []
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedItem !== this.props.selectedItem) {
            this.setState({
                fields: this.getFields(this.props.selectedItem),
                clientImages: this.getImages(),
                DeletedImages: !!this.props.selectedItem ? new Array(...this.props.selectedItem.QuestionContent) : []
            })
        }
    }

    getFields = (item = {}) => {
        let fieldTemplates = {
            SetName: new Field('SetName', '', ['empty']),
            SetLevel: new Field('SetLevel', "", ['empty']),
            QuestionTypeID: new Field('QuestionTypeID', item?.SetQuestionID ?? "", ['empty']),
            SetLabel: new Field('SetLabel', "", []),
            SetOperantID: new Field('SetOperantID', "", [], false, 'select-search'),
        }
        return fillFieldsFromData(fieldTemplates, item);
    }

    getImages = () => {
        return (!!this.props.selectedItem && this.props.selectedItem.QuestionContent && this.props.selectedItem.QuestionContent.length > 0) ?
            new Array(...this.props.selectedItem.QuestionContent).map((it, i) => Object.assign({}, it, it.DeleteImage = false))
            :
            []
    }

    submit = (event) => {
        event && event.preventDefault();

        let newFilesArray = this.state.files
        let oldFilesArray = this.state.clientImages
        if (this.state.fields.QuestionTypeID.value == SET_QUESTION_TYPE_IMAGE_ID) {
            const images = [...newFilesArray, ...oldFilesArray].filter(it => !it.DeleteImage)
            // Provera da li ima slika
            if (images.length === 0) {
                this.setState({errorMessage: "text.please_upload_atleast_one_image"})
                return;
            }

            // Provera da li sve slike imaju AnswerLabel
            const filesIncludesAnswers = images.every(it => it.AnswerLabel && it.AnswerLabel.length > 0)

            if (!filesIncludesAnswers) {
                this.setState({errorMessage: "text.please_fill_out_all_answer_labels"})
                return;
            } else {
                this.setState({errorMessage: false})
            }

            // Provera da li postoji barem jedna slika sa tacnim odgovorom
            const filesContainsCorrectAnswer = images.some(it => it.CorrectAnswer)

            if (!filesContainsCorrectAnswer) {
                this.setState({errorMessage: "text.please_set_one_answer_as_correct"})
                return;
            } else {
                this.setState({errorMessage: false})
            }
        }
        if (this.state.fields.QuestionTypeID.value == SET_QUESTION_TYPE_VIDEO_ID) {
            // Provera da li ima klipova
            if (this.state.clientImages.length === 0 && this.state.files.length === 0) {
                this.setState({errorMessage: "text.please_upload_video"})
                return;
            } else {
                this.setState({errorMessage: false})
            }
        }

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {

                if (this.props.selectedItem) {
                    let content = this.state.clientImages
                    if (this.state.fields.QuestionTypeID.value == SET_QUESTION_TYPE_VIDEO_ID) {
                        if (content[0]) content[0].DeleteImage = true
                    }
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get("user"),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields),
                            {
                                SetID: this.props.selectedItem.SetID,
                                id: this.props.selectedItem.SetID,
                                QuestionContent: content,
                                SetOperantID: this.state.fields.SetOperantID.value.value
                            }),
                        query: this.props.query,
                        resource: Resources.Sets,
                        piggyResource: Resources.Sets,
                        file: this.state.files,
                        fileResource: Resources.SetsImage,
                        id: this.props.selectedItem.SetID
                    }));
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get("user"),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                            SetOperantID: this.state.fields.SetOperantID.value.value
                        }),
                        query: this.props.query,
                        resource: Resources.Sets,
                        piggyResource: Resources.Sets,
                        file: this.state.files,
                        fileResource: Resources.SetsImage,
                    }));
                }
                this.props.close()
            }
        });
    }

    onDrop = (acceptedFiles) => {
        let files = []
        if (this.state.fields.QuestionTypeID.value == SET_QUESTION_TYPE_VIDEO_ID) { // ako je video
            files = [Object.assign(acceptedFiles[0], {preview: URL.createObjectURL(acceptedFiles[0])})]
        } else {
            files = this.state.files.concat(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })))
        }
        this.setState({
            files: files,
            canSubmit: true
        })
    };

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        });
    }

    onDropAccepted = () => {
        this.setState({
            dropzoneActive: false,
            filesUploadDialog: true
        });
    }

    handleInputChange = (name, value) => {
        if (name == "QuestionTypeID") {
            this.setState({
                canSubmit: true,
                fields: FieldsManager.updateField(this.state.fields, name, value),
                files: []
            });
        } else {
            this.setState({canSubmit: true, fields: FieldsManager.updateField(this.state.fields, name, value)});
        }
    };

    openDropzoneHandler = () => {
        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open()
        }
    }

    removePictureHandler = (index, arr) => {
        let array = this.state[arr]
        if (arr == "files") {
            if (index > -1) {
                array.splice(index, 1);
                this.setState({[arr]: array, canSubmit: true})
            }
        } else {
            array[index].DeleteImage = true
            this.setState({[arr]: array, canSubmit: true})
        }
    }

    setCorrectImageHandler = (index, array) => {
        let arrone = this.state.files
        let arrtwo = this.state.clientImages
        for (let i = 0; i < arrone.length; i++) {
            arrone[i].CorrectAnswer = false;
        }
        for (let i = 0; i < arrtwo.length; i++) {
            arrtwo[i].CorrectAnswer = false;
        }
        if (array == "files") {
            arrone[index].CorrectAnswer = true;
        } else if (array == "clientImages") {
            arrtwo[index].CorrectAnswer = true;
        }
        this.setState({files: arrone, clientImages: arrtwo, canSubmit: true})
    }

    AnswerLabelHandler = (value, index, array) => {
        let files = this.state[array]
        files[index].AnswerLabel = value;
        this.setState({[array]: files, canSubmit: true})
    }

    render() {
        const {translate, close, visible} = this.props;

        let images = this.state.files.map((item, i) => {

            return (
                <div className='mt-2' key={item.preview}>
                    <div className='drag-n-drop-images-preview position-relative'
                         style={{background: `url(${item.preview})`}}/>
                    <div className='flex justify-between'>
                        <div className="" onClick={() => {
                            this.setCorrectImageHandler(i, "files");
                        }}>
                            <section>
                                <div className="squaredFour">
                                    <input type="checkbox" checked={!!item.CorrectAnswer}/>
                                    <label htmlFor="squaredFour"/>
                                </div>
                            </section>
                        </div>
                        <i onClick={() => this.removePictureHandler(i, "files")}
                           className='fas fa-times color-orange font-25 cursor-pointer'/>
                    </div>
                    <div>
                        <input
                            style={{width: "100%", marginTop: "10px"}}
                            type="text"
                            placeholder={"Question Label"}
                            value={item.AnswerLabel}
                            className={'modal-form-control-input'}
                            onChange={(e) => this.AnswerLabelHandler(e.target.value, i, "files")}
                        />
                    </div>
                </div>
            )
        })
        let clientImages = !!this.props.selectedItem ? this.state.clientImages.map((elem, i) => {
            const imageUrl = !!elem.source && Env.getApiUrl("api/" + Resources.SetsImages, {
                access_token: LocalStorage.get('user').access_token,
                PathName: elem.source.split("/")[elem.source.split("/").length - 1]
            });
            if (elem.DeleteImage == false) {
                return (
                    <div className='mt-2' key={elem.source}>
                        <div className='drag-n-drop-images-preview position-relative'
                             style={{background: `url(${imageUrl})`}}/>
                        <div className='flex justify-between'>
                            <div className="remember-me" onClick={() => {
                                this.setCorrectImageHandler(i, "clientImages");
                            }}>
                                <section>
                                    <div className="squaredFour">
                                        <input type="checkbox" checked={!!elem.CorrectAnswer}/>
                                        <label htmlFor="squaredFour"></label>
                                    </div>
                                </section>
                            </div>
                            <i onClick={() => this.removePictureHandler(i, "clientImages")}
                               className='fas fa-times color-orange font-25 cursor-pointer'/>
                        </div>
                        <div>
                            <input
                                style={{width: "100%", marginTop: "10px"}}
                                type="text"
                                placeholder={"Question Label"}
                                value={elem.AnswerLabel}
                                className={'modal-form-control-input'}
                                onChange={(e) => this.AnswerLabelHandler(e.target.value, i, "clientImages")}
                            />
                        </div>
                    </div>
                )
            }
        }) : []

        let oldVideo = !!this.state.DeletedImages && this.state.DeletedImages.map(elem => {
            const imageUrl = !!elem.source && Env.getApiUrl("api/" + Resources.SetsImages, {
                access_token: LocalStorage.get('user').access_token,
                PathName: elem.source.split("/")[elem.source.split("/").length - 1]
            });
            return (
                <React.Fragment>
                    <i className="fas fa-times color-orange font-25 cursor-pointer opacity-0"/>
                    <video className='drag-n-drop-video-preview' controls>
                        <source src={imageUrl} type="video/mp4"/>
                        <source src={imageUrl} type="video/ogg"/>
                    </video>
                </React.Fragment>
            )
        })

        return (
            <React.Fragment>
                <div>{this.state.isLoading && this.state.images.map(elem => (
                    <img className="block-image" src={elem}/>
                ))} </div>
                <div className="grid-50-50">
                    <div>
                        <div className='field-label'>
                            <div className='field-label'>{translate("field.SetName")} *</div>
                            <FieldText
                                onChange={this.handleInputChange}
                                {...this.state.fields.SetName}
                                addClass={"modal-form-control-input"}
                            />
                        </div>

                        <div className='field-label'>
                            <div className='field-label'>{translate("field.SetOperantID")}</div>
                            <FieldDropdownSelect
                                onChange={this.handleInputChange}
                                {...this.state.fields.SetOperantID}
                                defaultOptions={true}
                                loadOptions={
                                    (inputValue, callback) => {
                                        axios.get(
                                            Env.getApiUrl("api/" + Resources.Lookup, {query: inputValue}),
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                                }
                                            }
                                        )
                                            .then((response) => {
                                                const result = processResponse(response);
                                                if (result && result.status === 0) {
                                                    const list = result.data.Operant?.map((it) => {
                                                        if (it.OperantID == this.state.fields.SetOperantID.value?.value) {
                                                            this.setState({
                                                                fields: FieldsManager.updateField(this.state.fields, "SetOperantID", {
                                                                    label: it.Operant,
                                                                    value: it.OperantID
                                                                })
                                                            })
                                                        }
                                                        return {label: it.Operant, value: it.OperantID};
                                                    });
                                                    callback(list);
                                                }
                                            })
                                    }
                                }
                            />
                        </div>


                    </div>

                    <div>
                        <div className='field-label'>
                            <div className='field-label'>{translate("field.Level")} *</div>
                            <FieldSelectSearch
                                values={{1: 1, 2: 2, 3: 3}}
                                onChange={this.handleInputChange}
                                {...this.state.fields.SetLevel}
                                addClass={"modal-form-control-input"}
                            />

                        </div>
                        <div className='field-label'>
                            <div className='field-label'>{translate("field.SD")}</div>
                            <FieldText
                                onChange={this.handleInputChange}
                                {...this.state.fields.SetLabel}
                                addClass={"modal-form-control-input"}
                            />
                        </div>
                    </div>
                </div>
                <div className='field-label'>
                    <div className='field-label'>{translate("field.QuestionTypeID")} *</div>
                    <FieldSelectSearch
                        values={SET_QUESTION_TYPES(translate)}
                        onChange={this.handleInputChange}
                        {...this.state.fields.QuestionTypeID}
                        addClass={"modal-form-control-input"}
                        disabled={!!this.props.selectedItem}
                    />
                </div>
                <div className="grid-50-50">
                    <div className='field-label'>
                        <div className='field-label '>{translate("field.QuestionContent")}
                            {this.state.fields.QuestionTypeID.value != "" && this.state.fields.QuestionTypeID.value != SET_QUESTION_TYPE_NOT_APPLICABLE_ID && (
                                <button type="button"
                                        onClick={this.openDropzoneHandler}
                                        className="btn btn-primary mt-2 ml-3 focus:ring-offset-inverse hover:cursor-pointer">UPLOAD
                                    CONTENT
                                </button>
                            )}
                        </div>
                        {(this.state.fields.QuestionTypeID.value != "" && this.state.fields.QuestionTypeID.value != SET_QUESTION_TYPE_NOT_APPLICABLE_ID) ? (
                                <Dropzone
                                    ref={this.dropzoneRef}
                                    onDrop={this.onDrop}
                                    onDragEnter={this.onDragEnter}
                                    onDragLeave={this.onDragLeave}
                                    onDropAccepted={this.onDropAccepted}
                                    maxFiles={this.state.fields.QuestionTypeID.value == SET_QUESTION_TYPE_VIDEO_ID ? 1 : 10}
                                    accept={this.state.fields.QuestionTypeID.value == SET_QUESTION_TYPE_VIDEO_ID ? "video/mp4" : "image/jpg, image/jpeg, image/png"}
                                    multiple={this.state.fields.QuestionTypeID.value == SET_QUESTION_TYPE_VIDEO_ID ? false : true}
                                    maxSize={100000000}
                                >
                                    {({getRootProps, getInputProps}) => (
                                        <section style={{opacity: "0", height: "0"}}>
                                            <div {...getRootProps()}
                                                 className={"select-from-library-button d-flex justify-content-center align-items-center " + (this.state.dropzoneActive ? " active" : "")}>
                                                <input {...getInputProps()} placeholder=""/>
                                                <div className='text-center'>
                                                    <p>{translate(this.state.fields.QuestionTypeID.value == 1 ? "field.drag_n_drop_video" : "field.drag_n_drop_image")}</p>
                                                    <em>{translate(this.state.fields.QuestionTypeID.value == 1 ? "field.video_types" : "field.image_types")}</em>
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            ) :
                            <span
                                className='mt-10'>{translate("text.enter_question_type_in_order_to_add_content")}</span>
                        }

                    </div>

                    <div></div>
                </div>

                {!!this.props.selectedItem && this.state.fields.QuestionTypeID.value == SET_QUESTION_TYPE_VIDEO_ID && !!this.state.files && this.state.files.length > 0 && (
                    <div className='field-label'>
                        <h3 className='text-center m-0'><i
                            className="fas fa-exclamation-triangle"></i>{translate("text.if_you_upload_new_video_old_one_will_be_deleted")}<i
                            className="fas fa-exclamation-triangle"></i></h3>
                    </div>
                )}
                {(this.state.errorMessage) && (
                    <div className='field-label'>
                        <h3 className='text-center m-0'>
                            <i className="fas fa-exclamation-triangle"/>
                            {translate(this.state.errorMessage)}
                            <i className="fas fa-exclamation-triangle"/>
                        </h3>
                    </div>
                )}
                {this.state.fields.QuestionTypeID.value != 3 && <div className="grid-50-50">
                    <div>
                        {!!this.props.selectedItem && <h1 className='text-center'>Current Set</h1>}
                        {!!this.props.selectedItem && this.state.fields.QuestionTypeID.value == SET_QUESTION_TYPE_IMAGE_ID && (
                            <>
                                <div
                                    className={this.state.fields.QuestionTypeID.value == SET_QUESTION_TYPE_IMAGE_ID ? "modal-images-container" : "modal-video-container"}>
                                    {!!this.props.selectedItem && this.props.selectedItem.QuestionContent && this.props.selectedItem.QuestionContent.length > 0 && clientImages}
                                </div>
                            </>
                        )}
                        {!!this.props.selectedItem && this.state.fields.QuestionTypeID.value == SET_QUESTION_TYPE_VIDEO_ID && oldVideo}
                    </div>
                    <div>
                        {!!this.props.selectedItem && <h1 className="text-center">Items to be added</h1>}
                        <div
                            className={this.state.fields.QuestionTypeID.value == SET_QUESTION_TYPE_IMAGE_ID ? "modal-images-container" : "modal-video-container"}>
                            {this.state.files.length > 0 && this.state.fields.QuestionTypeID.value == SET_QUESTION_TYPE_IMAGE_ID && images}
                            {/*{this.state.files.length > 4 && this.state.fields.QuestionTypeID.value == 2 && <i className="fas fa-angle-double-down scroll-down-animation"/>}*/}
                            {this.state.files.length > 0 && this.state.fields.QuestionTypeID.value == SET_QUESTION_TYPE_VIDEO_ID && (
                                <React.Fragment>
                                    <div className='w-100 text-right'>
                                        <i onClick={() => this.setState({files: [], canSubmit: true})}
                                           className="fas fa-times color-orange font-25 cursor-pointer"/>
                                    </div>
                                    <video
                                        key={this.state.files[0].preview}
                                        className='drag-n-drop-video-preview' controls>
                                        <source src={this.state.files[0].preview} type="video/mp4"/>
                                        <source src={this.state.files[0].preview} type="video/ogg"/>
                                    </video>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>}
                <div className="pt-5">
                    <div className="flex justify-end">
                        <button
                            type="button"
                            onClick={close}
                            className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer"
                        >
                            Close
                        </button>

                        <button
                            type="submit"
                            onClick={!!this.state.canSubmit ? this.submit : null}
                            className={classNames("btn ml-3 focus:ring-offset-inverse hover:cursor-pointer", !!this.state.canSubmit ? "btn-primary" : "btn-disabled")}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(state => state)(SetDialog);
