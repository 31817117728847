import React, {Component} from "react";

export default class FieldCheckbox extends Component {

    render() {
        return (
            <React.Fragment>
                <input type={"checkbox"}
                       name={this.props.name}
                       checked={!!this.props.value && (this.props.value !== "0")}
                       onChange={(event) => {
                           this.props.onChange(this.props.name, !!event.target.checked);
                       }}
                       disabled={this.props.disabled}
                />
                {this.props.errorMessage && (
                    <p className="mt-2 text-sm text-red-600">
                        {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Please fill out this field."}
                    </p>
                )}
            </React.Fragment>
        )
    }
}