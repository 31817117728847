import {watchLoginCall, watchRegisterCall, watchResetPasswordCall, watchResetPasswordConfirmCall} from "./userSaga";
import {all} from "redux-saga/effects";
import {
    watchCreateResource,
    watchCreateSecondResource,
    watchDeleteResource,
    watchDownloadDocument,
    watchGetAuthResource,
    watchGetResource,
    watchGetResourceDetails,
    watchGetSecondResource,
    watchUpdateResource,
    watchUpdateSecondResource,
    watchUploadDocument
} from "./resourceSaga";
import {watchPushNotification} from "./ui";
import {watchDownload} from "./download";

/*
 * Single entry point to start all Sagas at once
 =========================================================================================*/
export default function* rootSaga() {
    yield all([
        // Login/Register
        watchLoginCall(),
        watchRegisterCall(),
        watchResetPasswordCall(),
        watchResetPasswordConfirmCall(),

        // Resource
        watchGetResource(),
        watchGetSecondResource(),
        watchGetResourceDetails(),
        watchCreateResource(),
        watchCreateSecondResource(),
        watchUpdateResource(),
        watchUpdateSecondResource(),
        watchDeleteResource(),

        watchUploadDocument(),
        watchDownloadDocument(),
        watchDownload(),

        watchGetAuthResource(),

        watchPushNotification()
    ]);
}
