import React, {useEffect, useRef, useState} from 'react'
import {InformationCircleIcon} from "@heroicons/react/solid";
import IconSort from "../icons/sort";
import {classNames, fieldsToCells} from "../../util/util";

const Table = (props) => {
    const {
        haveActions,
        isLoading,
        data,
        fields,
        onEdit,
        onSortChange,
        onRowClick,
        sortBy,
        sort,
        translate,
        getActions
    } = props;
    const [actionColWidth, setActionColWidth] = useState(null);
    let actionColRef = useRef(null)
    useEffect(() => {
        if (!isLoading) {
            setActionColWidth(actionColRef?.current?.offsetWidth + "px")
        }
    }, [isLoading]);

    const columns = Object.keys(fields)
        .filter(
            it => !fields[it]?.metadata?.hideTable
        )
        .map((it, i) => {

            const isSortable = !fields[it]?.metadata?.omitSort;
            return (
                <th
                    onClick={(isSortable && onSortChange) ? () => onSortChange(it) : null}
                    className={classNames("px-4 py-2 bg-kp-blue-dark text-left text-xs font-medium text-white uppercase tracking-wider", isSortable ? "cursor-pointer" : "")}
                >
                    <div className="flex">
                        <div className="mr-3">
                            {translate("table." + it)}
                        </div>

                        {!!onSortChange && isSortable && (
                            <IconSort
                                className="min-w-icon ml-auto"
                                active={sortBy === it}
                                sort={sort}
                            />
                        )}
                    </div>
                </th>
            )
        });

    const row = data.map((it, i) => {
        const fieldRows = fieldsToCells(fields, it);
        return (
            (
                <tr
                    onClick={() => {
                        onRowClick && onRowClick(it)
                    }}
                    key={i}
                    className={(i % 2 === 0) ? (!it.ArchivedDate ? '' : "bg-secondary-100") : (!it.ArchivedDate ? 'bg-secondary-50' : 'bg-secondary-100')}>

                    {fieldRows}

                    {haveActions && (
                        getActions(it)
                    )}
                </tr>
            )
        )
    });

    const generateRandomSkeletonWidth = () => {
        let classes = ['w-3/12', 'w-4/12', 'w-5/12', 'w-6/12', 'w-7/12', 'w-8/12', 'w-9/12']
        return classes[Math.floor(Math.random() * classes.length)];
    }

    const getSkeletonRows = (columns, n = 10) => {
        const skeletonRows = [];

        for (let i = 1; i <= n; i++) {
            const dataCells = columns.map(col => (
                <td
                    className={"animate-pulse border-secondary-200 bg-secondary-50 p-3 text-left text-secondary-900 border-r font-bold w-32 h-12"}
                >
                    <div className={"h-4 bg-secondary-400 rounded " + (generateRandomSkeletonWidth())}/>
                </td>
            ));

            skeletonRows.push(
                <tr>
                    {dataCells}
                    {haveActions && (
                        <td
                            className={"animate-pulse border-secondary-200 bg-secondary-50 p-3 text-left text-secondary-900 border-r font-bold h-12 w-full"}
                        >
                            <div className={"h-4 bg-secondary-400 rounded " + (generateRandomSkeletonWidth())}/>
                        </td>
                    )}
                </tr>
            )
        }

        return skeletonRows;
    }

    return (
        <div className="">
            <table className="w-full min-w-full divide-y divide-secondary-200">
                <thead>
                <tr>
                    {columns}
                    {haveActions && (
                        <th
                            className={classNames("px-6 py-3 text-center bg-kp-blue-dark text-xs font-medium text-white uppercase tracking-wider", isLoading ? "w-full" : "w-10")}
                            ref={actionColRef}
                            style={{minWidth: actionColWidth}}
                        >
                            Actions
                        </th>
                    )}
                </tr>
                </thead>
                <tbody className="bg-inverse divide-y divide-secondary-200">

                {!isLoading && row}

                {isLoading && getSkeletonRows(columns)}

                {!isLoading && (row.length === 0) && (
                    <tr>
                        <td className={"items-center"} colSpan={Object.keys(fields).length + (onEdit ? 1 : 1)}>
                            <div className="bg-secondary-50 p-4 test">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <InformationCircleIcon className="h-5 w-5 text-secondary-400"
                                                               aria-hidden="true"/>
                                    </div>
                                    <div className="ml-3 flex-1 md:flex md:justify-between">
                                        <p className="text-sm text-black-700">No data</p>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}

export default Table;