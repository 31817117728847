import React from "react";

export const GoBackIcon = ({
                               iconClassName = '', onClick = () => {
    }
                           }) => (
    <i
        onClick={onClick}
        className={"fas fa-chevron-left cursor-pointer mr-2 " + (iconClassName)}/>
)

const PageHeading = (props) => {
    const addTitleClass = props.titleClass ? " " + props.titleClass : "";
    return (
        <div className="lg:flex lg:items-center lg:justify-between">
            <div className="flex-1 min-w-0">
                <h2 className={"text-xl font-bold leading-7 text-gray-900 sm:text-3xl" + addTitleClass}>{props.title}</h2>
            </div>

            {!!props.children && (
                <div className="mt-4 md:flex md:mt-0 md:ml-4">
                    {props.children}
                </div>
            )}
        </div>
    )
}

export default PageHeading;
