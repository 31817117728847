import React, {Component} from "react";
import {connect} from "react-redux";
import Dialog from '../../components/dialog'
import {Field, FieldsManager} from "../../data/services/fields";
import FieldText from "../field-text";
import Button from "../button";
import {createResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";

class LookupDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            canSubmit: false
        };
    }

    getFields = () => {
        return {
            LookupValue: new Field('LookupValue', this.props.LookupValue ? this.props.LookupValue : "", ['empty']),
        }
    }

    submit = (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                if (!!this.props.LookupValue) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get("user"),
                        params: Object.assign({}, {
                            Lookup: this.props.LookupKey,
                            Id: this.props.LookupID,
                            Value: this.state.fields.LookupValue.value,
                        }),
                        query: {},
                        resource: Resources.Lookup,
                        piggyResource: Resources.Lookup,
                    }));
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get("user"),
                        params: Object.assign({}, {
                            Lookup: this.props.LookupKey,
                            Value: this.state.fields.LookupValue.value,
                        }),
                        resource: Resources.Lookup,
                        query: this.props.query,
                        piggyResource: Resources.Lookup,
                    }));
                }
                this.props.close()
            }
        });
    }

    handleInputChange = (name, value) => {
        this.setState({canSubmit: true, fields: FieldsManager.updateField(this.state.fields, name, value)});
    };


    render() {
        const {translate, close} = this.props;


        return (
            <Dialog
                visible={true}
                onClose={close}
                translate={translate}
                canSubmit={this.state.canSubmit}
                modalTitle={this.props.modalTitle}
            >
                <div className='modal-form-control-single'>
                    <div className='field-label'>{this.props.translate("field.LookupValue")}*</div>
                    <FieldText
                        onChange={this.handleInputChange}
                        {...this.state.fields.LookupValue}
                        addClass={"modal-form-control-input"}
                    />
                </div>
                <div className="pt-5">
                    <div className="flex justify-end">
                        <Button
                            type="outline"
                            className="btn btn-outline focus:ring-offset-inverse"
                            onClick={close}
                        >
                            {translate("btn.cancel")}
                        </Button>
                        <Button
                            className="btn btn-primary ml-3 focus:ring-offset-inverse"
                            onClick={this.submit}
                        >
                            {translate(!!this.props.LookupValue ? "btn.update" : "btn.create")}
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default connect(state => state)(LookupDialog);
