import React, {Component} from "react";
import {connect} from "react-redux";
import Dialog from '../../components/dialog'
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {getSecondResource} from "../../data/actions/secondResource";
import {checkPerm, getProp, UPDATE_PERM} from "../../util/util";
import ReportDialog from "./index";
import {Field} from "../../data/services/fields";
import SimpleTable from "../simple-table";
import {PencilIcon} from "@heroicons/react/solid";
import {PauseIcon} from "@heroicons/react/outline";

class ReportHistoryDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            selectedItem: null,
            editModal: false
        };
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.ReportHistory
        }))
    }

    handleEditClick = (item) => {
        this.setState({selectedItem: item, editModal: true})
    }

    closeEditModal = (item) => {
        this.setState({editModal: false})
    }

    getQuery = () => {
        return {
            ReportID: this.props.ReportID,
            sort: "DESC",
            sortBy: "ReportID"
        }
    }

    getFields = () => {
        return {
            Operant: new Field("Operant", '', [], false, 'text'),
            ReportMilestone: new Field("ReportMilestone", '', [], false, 'text'),
            ReportObservationalScore: new Field("ReportObservationalScore", '', [], false, 'text'),
            ReportScore: new Field("ReportScore", '', [], false, 'text'),
            CreateUpdateDate: new Field("CreateUpdateDate", '', [], false, 'datetimezone'),
            ReportDate: new Field("ReportDate", '', [], false, 'datetimezone'),
            ReportComment: new Field("ReportComment", '', [], false, 'text'),
        }
    }

    render() {
        const {translate, close, secondResource, resource} = this.props;

        const data = getProp(secondResource, "data.history.list", [])
        const count = getProp(secondResource, "data.history.count", [])

        return (
            <Dialog
                visible={true}
                onClose={close}
                translate={translate}
                modalTitle={this.props.modalTitle}
            >
                <div className="mt-10">
                    <SimpleTable
                        data={data}
                        count={count}

                        disablePagination

                        fields={this.state.fields}
                        translate={translate}
                        isLoading={(!!secondResource.isLoading || !!resource.isLoading)}

                        onCustomAction={(item) => {
                            if (item.ReportStatusID == 3 && checkPerm(Resources.Report, UPDATE_PERM)) {
                                return (
                                    <button
                                        title="View"
                                        type="button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            e.nativeEvent.stopImmediatePropagation();
                                            this.handleEditClick(item)
                                        }}
                                        className="mx-1 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-inverse hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500"
                                    >
                                        <PencilIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-gray-400"
                                                    aria-hidden="true"/>
                                        <span className="hidden md:inline">{translate("btn.edit")}</span>
                                    </button>
                                )
                            }
                            if (item.ReportStatusID == 2 && checkPerm(Resources.Report, UPDATE_PERM)) {
                                return (
                                    <button
                                        title="View"
                                        type="button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            e.nativeEvent.stopImmediatePropagation();
                                            this.props.history.push(`/report/start/${item.ReportID}`)
                                        }}
                                        className="mx-1 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-inverse hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500"
                                    >
                                        <PauseIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-gray-400"
                                                   aria-hidden="true"/>
                                        <span className="hidden md:inline">{translate("btn.Resume")}</span>
                                    </button>
                                )
                            }
                        }}
                    />
                </div>

                <div className="flex justify-end mt-10">
                    <button type="button"
                            onClick={close}
                            className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer">{translate("btn.close")}
                    </button>
                </div>

                {!!this.state.editModal && (
                    <ReportDialog
                        modalTitle={translate("text.update_report")}
                        query={this.getQuery()}
                        reportQuery={this.props.reportQuery}
                        translate={translate}
                        clientItem={this.state.selectedItem}
                        retake={false}
                        quizzStart={false}
                        {...this.props}
                        close={this.closeEditModal}
                        fromHistory={true}
                    />
                )}
            </Dialog>
        );
    }
}

export default connect(state => state)(ReportHistoryDialog);
