export function download(data) {
    return {
        type: 'DOWNLOAD',
        data: data
    };
}

export function doneDownload(data) {
    return {
        type: 'DONE_DOWNLOAD',
        data: data
    };
}

export function errorDownload(data) {
    return {
        type: 'ERROR_DOWNLOAD',
        data: data
    };
}
