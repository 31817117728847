import React, {Component} from "react";
import {connect} from "react-redux";
import {deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {checkPerm, CREATE_PERM, fieldsSearchToHtml, fillFieldsFromData, getProp} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import AssessmentsDialog from "../../components/assessmentDialog";
import AssessmentsHistoryDialog from "../../components/assessmentDialog/historyDialog";
import LayoutDashboard from "../../components/layout-dashboard";
import PageHeading from "../../components/page-heading";
import FieldText from "../../components/field-text";
import SimpleTable from "../../components/simple-table";
import ModalConfirm from "../../components/modal/modal-confirm";
import ClipboardListIcon from "@heroicons/react/outline/ClipboardListIcon";
import Dialog from "../../components/dialog";
import ArchivedSwitch from "../../components/archived-switch";

// todo: 1) Table actions, 2) History, 3) missing logic on handle input change from AssessmentsDialog

class AssessmentsView extends Component {

    constructor(props) {
        super(props);
        this.excludedFromTable = ['AssessmentOperantID', 'AssessmentExample', 'AssessmentImagesQuantity', "AssessmentObjective", "AssessmentScoringZero", "AssessmentScoringHalf", "AssessmentScoringOne", "AssessmentTrynew"];
        this.state = {
            fields: this.getFields(null, this.excludedFromTable),
            searchFields: this.getSearchFields(),
            limit: 10,
            paginationPage: 1,
            offset: 0,
            sort: 'ASC',
            sortBy: "",
            confirmDialog: false,
            selectedItem: null,
            deleteID: null,
            input: "",
            contentFormDialog: false,
            contentFormDialogDirty: false,
            confirmModalTitle: "",
            confirmModalText: "",
            confirmModalOpen: false
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!this.props.resource.data && !this.props.resource.isLoading && prevProps.resource.isLoading) {
            const list = Object.assign({}, getProp(this.props.resource.data, "list", []));

            // Table fields
            this.setState({
                fields: this.getFields(list, this.excludedFromTable)
            })
        }
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.Assessments
        }))
    };

    getQuery = () => {
        return {
            limit: this.state.limit,
            query: this.state.input,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            archived: this.state.archived ? 1 : 0,
            ...FieldsManager.getFieldKeyValues(this.state.searchFields)
        }
    }

    deleteAssessment = () => {
        if (this.state.selectedItem.ArchivedDate === null) {
            this.props.dispatch(deleteResource({
                user: LocalStorage.get('user'),
                query: {
                    id: this.state.selectedItem?.AssessmentID,
                },
                piggyQuery: this.getQuery(),
                resource: Resources.Assessments,
                piggyResource: Resources.Assessments
            }))
        } else {
            this.props.dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: {
                    AssessmentID: this.state.selectedItem?.AssessmentID,
                    ArchivedDate: 1
                },
                query: this.getQuery(),
                resource: Resources.Assessments,
                piggyResource: Resources.Assessments
            }));
        }

        this.toggleConfirmDialog()
    }
    /** UI Events
     ================================================================= */
    handleFilterChange = (name, val) => {
        this.setState({
            [name]: val,
            offset: 0,
            paginationPage: 1,
        }, this.fetchData)
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    toggleConfirmDialog = (item) => {
        const selectedItem = item || this.state.selectedItem;
        this.setState({confirmModalOpen: !this.state.confirmModalOpen, selectedItem: selectedItem});
    }

    addNewClient = (item = null) => {
        this.setState({addNewClient: !this.state.addNewClient, selectedItem: item})
    }

    toggleArchived = () => this.setState({archived: !this.state.archived}, () => this.fetchData())

    historyDialogHandler = id => this.setState({historyDialog: true, id: id})

    /** Helpers
     * ================================================================ */
    getPrimaryKey = () => {
        return "AssessmentID";
    }

    getResourceName = () => {
        return Resources.Assessments;
    }

    getFields = (item = null, excludeFields = null) => {
        // AssessmentOperantID
        const fieldTemplates = {
            AssessmentName: new Field("AssessmentName", '', [''], false, 'text'),
            AssessmentLevel: new Field("AssessmentLevel", '', [''], false, 'select'),
            OperantID: new Field("OperantID", '', [''], false, 'select'), // AssessmentOperantID
            AssessmentMilestone: new Field("AssessmentMilestone", '', [''], false, 'number'),
            AssessmentTypeID: new Field("AssessmentTypeID", '', [''], false, 'select'),

            AssessmentExample: new Field("AssessmentExample", '', [''], false, 'textarea'),
            AssessmentImagesQuantity: new Field("AssessmentImagesQuantity", '', [''], false, 'text'),
            AssessmentObjective: new Field("AssessmentObjective", '', [''], false, 'text'),
            AssessmentScoringZero: new Field("AssessmentScoringZero", '', [''], false, 'textarea'),
            AssessmentScoringHalf: new Field("AssessmentScoringHalf", '', [''], false, 'textarea'),
            AssessmentScoringOne: new Field("AssessmentScoringOne", '', [''], false, 'textarea'),
            AssessmentTrynew: new Field("AssessmentTrynew", '', [''], false, 'text'),

            AssessmentDescription: new Field("AssessmentDescription", '', [''], false, 'textarea'),
            SetName: new Field('SetName', '', ['empty'], false, 'text'),
        }

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getSearchFields = () => {
        return {
            AssessmentLevel: new Field("AssessmentLevel", '', [''], false, 'select'),
            AssessmentOperantID: new Field("AssessmentOperantID", '', [''], false, 'select-search'), // AssessmentOperantID
            AssessmentMilestone: new Field("AssessmentMilestone", '', [''], false, 'select'),
            AssessmentTypeID: new Field("AssessmentTypeID", '', [''], false, 'select')
        }
    }

    confirmCloseFormDialog = () => {
        if (!this.state.contentFormDialogDirty) {
            this.toggleContentFormDialog();
        } else {
            this.setState({
                confirmModalOpen: () => this.toggleContentFormDialog(null),
                confirmModalTitle: "Confirm",
                confirmModalText: "All entered data will be lost. Do you still want to continue?"
            })
        }
    }

    toggleContentFormDialog = (item = null) => {
        this.setState({
            contentFormDialog: !this.state.contentFormDialog,
            selectedItem: item,
            contentFormDialogDirty: false,
            confirmModalOpen: false
        })
    }

    setDialogStateDirty = (isDirty) => {
        this.setState({
            contentFormDialogDirty: isDirty
        })
    }

    handleSearchSelectQueryChange = (name, value) => {
        this.setState({searchFields: FieldsManager.updateField(this.state.searchFields, name, value)}, this.fetchData);
    }

    render() {
        const {translate, resource} = this.props;

        const data = getProp(resource.data, "list", []);
        const count = getProp(resource.data, "count", 0);
        const isLoading = getProp(this.props, "resource.isLoading", false);

        const searchFieldsHtml = fieldsSearchToHtml(Object.values(Object.assign({}, this.state.searchFields)), translate, this.handleSearchSelectQueryChange, {
            AssessmentOperantID: {
                api: 'api/' + Resources.Operant,
                query: {},
                searchMap: (it) => ({
                    label: it.Operant,
                    value: it.OperantID
                })
            },
            AssessmentLevel: {
                1: 1,
                2: 2,
                3: 3
            },
            AssessmentMilestone: {
                1: 1,
                2: 2,
                3: 3,
                4: 4,
                5: 5,
                6: 6,
                7: 7,
                8: 8,
                9: 9,
                10: 10
            }
        });

        return (
            <LayoutDashboard {...this.props} >
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12 relative">
                    <PageHeading
                        title="Assessments"
                        titleClass="pb-1"
                    >
                        <ArchivedSwitch
                            classNameContainer="h-9 mr-2 mt-1"
                            translate={translate}
                            value={this.state.archived}
                            onChange={this.toggleArchived}
                        />

                        {
                            checkPerm(this.getResourceName(), CREATE_PERM) && (
                                <button
                                    className="btn btn-primary ml-3"
                                    onClick={() => this.addNewClient()}
                                >
                                    Add New Assessment
                                </button>
                            )
                        }
                    </PageHeading>

                    <FieldText
                        placeholder={translate("text.search")}
                        className="w-72"
                        value={this.state.input}
                        name="input"
                        onChange={this.handleFilterChange}
                    />

                    {searchFieldsHtml}

                    <SimpleTable
                        data={data}
                        count={count}

                        fields={this.state.fields}
                        translate={this.props.translate}
                        isLoading={isLoading}

                        limit={this.state.limit}
                        offset={this.state.offset}
                        paginationPage={this.state.paginationPage}
                        onOffsetChange={this.handleUpdateOffset}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onDelete={checkPerm(this.getResourceName(), CREATE_PERM) ? (item) => this.toggleConfirmDialog(item) : null}
                        onDeleteLabel={translate("btn.archive")}
                        onRestore={checkPerm(this.getResourceName(), CREATE_PERM) ? (item) => this.toggleConfirmDialog(item) : null}
                        onEdit={checkPerm(this.getResourceName(), CREATE_PERM) ? (item) => this.addNewClient(item) : null}
                        customActions={
                            [{
                                action: (item) => this.setState({historyDialog: true, id: item.AssessmentID}),
                                label: translate("btn.history"),
                                icon: <ClipboardListIcon className={"md:-ml-1 md:mr-2 h-5 w-5 text-gray-400"}/>
                            }]
                        }
                    />

                </div>

                <Dialog
                    onClose={this.addNewClient}
                    visible={!!this.state.addNewClient}
                    translate={translate}
                    modalTitle={this.props.modalTitle}
                    canSubmit={this.state.canSubmit}
                    blurBackdrop={true}
                >
                    <AssessmentsDialog
                        close={this.addNewClient}
                        modalTitle={!!this.state.selectedItem ? translate("text.update_assessment") : translate("text.new_content")}
                        query={this.getQuery()}
                        translate={translate}
                        selectedItem={this.state.selectedItem}
                    />
                </Dialog>

                <Dialog
                    onClose={() => this.setState({historyDialog: false, id: null})}
                    visible={!!this.state.historyDialog}
                    translate={translate}
                    modalTitle={this.props.modalTitle}
                    blurBackdrop={true}
                >
                    <AssessmentsHistoryDialog
                        close={() => this.setState({historyDialog: false, id: null})}
                        modalTitle={translate("text.assessment_history")}
                        translate={translate}
                        id={this.state.id}
                    />
                </Dialog>

                <ModalConfirm
                    visible={!!this.state.confirmModalOpen}
                    title={translate(this.state.selectedItem?.ArchivedDate === null ? "text.delete_assessment" : "text.restore_assessment")}
                    text={`${translate(this.state.selectedItem?.ArchivedDate === null ? "text.are_you_sure_you_want_to_delete" : "text.are_you_sure_you_want_to_restore")} ${this.state.selectedItem?.AssessmentName}?`}
                    onClose={this.toggleConfirmDialog}
                    onConfirm={() => this.deleteAssessment()}
                />
            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(AssessmentsView);
