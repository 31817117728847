import React, {Component} from "react";
import {connect} from "react-redux";
import {deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {checkPerm, CREATE_PERM, getProp} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import SetDialog from "../../components/setDialog";
import LayoutDashboard from "../../components/layout-dashboard";
import PageHeading from "../../components/page-heading";
import FieldText from "../../components/field-text";
import SimpleTable from "../../components/simple-table";
import ModalConfirm from "../../components/modal/modal-confirm";
import DialogDefault from "../../components/dialog";
import ArchivedSwitch from "../../components/archived-switch";

class SetsView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            limit: 10,
            paginationPage: 1,
            offset: 0,
            sort: 'ASC',
            sortBy: "",
            confirmDialog: false,
            addNewClient: false,
            selectedItem: null,
            deleteID: null,
            archived: 0,
            query: '',
            input: '',
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevState.sort !== this.state.sort) || (prevState.archived !== this.state.archived) || (prevState.limit !== this.state.limit) || (prevState.sortBy !== this.state.sortBy && this.state.sortBy !== "")) {
            this.fetchData();
        }
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.Sets
        }))
    };

    updateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        })
    };

    updateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, this.fetchData);
    }

    handleFilterChange = (name, val) => {
        this.setState({
            [name]: val,
            offset: 0,
            paginationPage: 1,
        }, this.fetchData)
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    editClient = (item = null) => {
        this.setState({selectedItem: Object.assign({}, item), addNewClient: true})
    }

    deleteClient = () => {
        const id = this.state.selectedItem.SetID

        if (this.state.selectedItem.ArchivedDate === null) {
            this.props.dispatch(deleteResource({
                user: LocalStorage.get('user'),
                query: {
                    id: id,
                },
                piggyQuery: this.getQuery(),
                resource: Resources.Sets,
                piggyResource: Resources.Sets
            }))
        } else {
            this.props.dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: {
                    SetID: id,
                    ArchivedDate: 1
                },
                query: this.getQuery(),
                resource: Resources.Sets,
                piggyResource: Resources.Sets
            }));
        }
        this.toggleConfirmDialog()
    }

    toggleArchived = () => this.setState({archived: !this.state.archived})

    toggleSetsFormDialog = (item = null) => {
        this.setState({addNewClient: !this.state.addNewClient, selectedItem: item});
    }

    toggleConfirmDialog = (item = null) => {
        this.setState({confirmDialog: !this.state.confirmDialog, selectedItem: item});
    }

    showAddNewClient = () => this.setState({addNewClient: true, selectedItem: null});
    hideAddNewClient = () => this.setState({addNewClient: false, selectedItem: null});

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    /** Helpers
     * ================================================================ */
    getPrimaryKey = () => {
        return "SetID";
    }

    getResourceName = () => {
        return Resources.Sets;
    }

    getFields = () => {
        return {
            SetName: new Field("SetName", '', ['empty'], false, 'text'),
            SetLevel: new Field("SetLevel", '', [], false, 'text'),
            Operant: new Field("Operant", '', [], false, 'text'),
            SetCreator: new Field("SetCreator", '', [], false, 'text'),
        }
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            query: this.state.input,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            archived: this.state.archived ? 1 : 0,
            notApplicable: 0
        }
    }

    render() {
        const {translate, resource} = this.props;
        const data = getProp(resource.data, "list", []).map((it) => {
            const hide = (it.UpdatedByContactID != LocalStorage.get('user')?.Contact?.ContactID) && (!!it.UpdatedByContactID);
            it.editHidden = hide;
            it.deleteHidden = hide;
            return it;
        });
        const count = getProp(resource.data, "count", 0)

        return (
            <LayoutDashboard {...this.props}>
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12 relative">
                    <PageHeading
                        title="Sets"
                        titleClass="pb-1"
                    >
                        <ArchivedSwitch
                            classNameContainer="h-9 mr-2 mt-1"
                            translate={translate}
                            value={this.state.archived}
                            onChange={this.toggleArchived}
                        />

                        {
                            checkPerm(this.getResourceName(), CREATE_PERM) && (
                                <button
                                    className="btn btn-primary ml-3"
                                    onClick={() => this.toggleSetsFormDialog()}
                                >
                                    {translate("btn.add_new_set")}
                                </button>
                            )
                        }

                    </PageHeading>

                    <FieldText
                        placeholder={translate("text.search")}
                        className="w-72"
                        value={this.state.input}
                        name="input"
                        onChange={this.handleFilterChange}
                    />

                    <SimpleTable
                        data={data}
                        count={count}

                        fields={this.state.fields}
                        translate={this.props.translate}
                        isLoading={resource.isLoading}

                        limit={this.state.limit}
                        offset={this.state.offset}
                        paginationPage={this.state.paginationPage}
                        onOffsetChange={this.handleUpdateOffset}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onDelete={(item) => this.toggleConfirmDialog(item)}
                        onRestore={(item) => this.toggleConfirmDialog(item)}
                        onEdit={(item) => this.editClient(item)}
                    />
                </div>

                <DialogDefault
                    title={this.props.modalTitle}
                    widthClass={this.props.widthClass}
                    visible={this.state.addNewClient}
                    blurBackdrop={true}
                    onClose={this.hideAddNewClient}
                    translate={translate}
                    disableOverflow
                >
                    {this.state.addNewClient && (
                        <SetDialog
                            close={this.hideAddNewClient}
                            modalTitle={!!this.state.selectedItem ? translate("text.update_set_profile") : translate("text.new_set_profile")}
                            translate={translate}
                            blurBackdrop={true}
                            query={this.getQuery()}
                            selectedItem={this.state.selectedItem}
                        />
                    )}
                </DialogDefault>

                <ModalConfirm
                    visible={this.state.confirmDialog}
                    title={translate(this.state.selectedItem?.ArchivedDate === null ? "text.delete_set" : "text.restore_set")}
                    text={`${translate(this.state.selectedItem?.ArchivedDate === null ? "text.are_you_sure_you_want_to_delete" : "text.are_you_sure_you_want_to_restore")} ${this.state.selectedItem?.SetName}?`}
                    translate={translate}
                    onClose={this.toggleConfirmDialog}
                    onConfirm={() => this.deleteClient()}
                />

            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(SetsView);
