import React, {Component} from "react";
import {connect} from "react-redux";
import Dialog from '../../components/dialog'
import {Field, FieldsManager} from "../../data/services/fields";
import FieldText from "../field-text";
import FieldTextarea from "../field-textarea";
import Resources from "../../data/services/resources";
import LocalStorage from "../../util/localStorage";
import {updateResource} from "../../data/actions/resource";
import {Redirect} from "react-router-dom";
import {getProp} from "../../util/util";

class QuizzFinishDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            redirect: false,
            canRedirect: false
        };
    }

    getFields = () => {
        return {
            ReportObservationalScore: new Field('ReportObservationalScore', "", ['empty']),
            ReportComment: new Field('ReportComment', "", ['empty']),
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource !== this.props.resource && prevState.redirect != this.state.redirect) {
            this.setState({canRedirect: true})
        }
    }

    submit = (event) => {
        event && event.preventDefault();
        let currTries = +getProp(this.props.resource.data, "quiz", {}).ReportCurrentTries
        let repTry = +getProp(this.props.resource.data, "quiz", {}).ReportTry
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get("user"),
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                        ReportID: this.props.clientItem.ReportID,
                    }),
                    query: (currTries === repTry || this.props.clientItem.QuestionTypeID == 1 || this.props.clientItem.QuestionTypeID == 3) ? {ClientID: this.props.clientItem.ClientID} : {ReportID: this.props.clientItem.ReportID},
                    resource: Resources.ReportFinish,
                    piggyResource: (currTries === repTry || this.props.clientItem.QuestionTypeID == 1 || this.props.clientItem.QuestionTypeID == 3) ? Resources.Report : Resources.ReportRandomize,
                }));
                this.setState({redirect: true})
            }
        });
    }

    handleInputChange = (name, value) => {
        if (name == "QuestionTypeID") {
            this.setState({fields: FieldsManager.updateField(this.state.fields, name, value), files: []});
        } else {
            this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
        }
    };

    render() {
        const {translate, close} = this.props;
        if (!!this.state.canRedirect) {
            return <Redirect to={`/report/${this.props.clientItem.ClientID}`}/>;
        }

        return (
            <Dialog
                visible={true}
                translate={translate}
                onClose={close}
                modalTitle={this.props.modalTitle}
            >
                {!!this.state.canRedirect && <Redirect to={`/report/${this.props.clientItem.ClientID}`}/>}
                <div className='start-assessment-text'>
                    <div><span>{translate("text.Objective")}: </span>{this.props.clientItem.ReportObjective}</div>
                    <div><span>{translate("text.ReportExample")}: </span>{this.props.clientItem.ReportExample}</div>
                    <div><span>{translate("text.ReportScoringOne")}: </span>{this.props.clientItem.ReportScoringZero}
                    </div>
                    <div><span>{translate("text.ReportScoringOne")}: </span>{this.props.clientItem.ReportScoringOne}
                    </div>
                    <div><span>{translate("text.ReportScoringHalf")}: </span>{this.props.clientItem.ReportScoringHalf}
                    </div>
                    <div><span>{translate("text.Grade")}: </span>{this.props.clientItem.ReportScore}</div>
                </div>
                <div className=''>
                    <div>
                        <div className='modal-form-control'>
                            <div className='field-label'>{translate("field.ReportObservationalScore")}*</div>
                            <FieldText
                                onChange={this.handleInputChange}
                                {...this.state.fields.ReportObservationalScore}
                                addClass={"modal-form-control-input"}
                            />
                        </div>
                        <div className='modal-form-control pt-2'>
                            <div className='field-label'>{translate("field.ReportComment")}*</div>
                            <FieldTextarea
                                onChange={this.handleInputChange}
                                {...this.state.fields.ReportComment}
                                addClass={"form-control w-100"}
                            />
                        </div>
                    </div>
                </div>
                <div className="pt-5">
                    <div className="flex justify-end">
                        <button type="button"
                                className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer"
                                onClick={() => this.setState({canRedirect: true})}
                        >{translate("btn.cancel")}
                        </button>
                        <button type="submit"
                                onClick={this.submit}
                                className="btn btn-primary ml-3 focus:ring-offset-inverse hover:cursor-pointer"
                        >{translate("btn.finish")}
                        </button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default connect(state => state)(QuizzFinishDialog);
