import React, {Component} from "react";
import {connect} from "react-redux";
import Layout from "../../components/layout";
import {Field, FieldsManager} from "../../data/services/fields";
import FieldText from "../../components/field-text";
import FieldPassword from "../../components/field-password";
import {login, resetUserMessage} from "../../data/actions/user";
import {Link} from "react-router-dom";
import LoaderSmall from "../../components/loader-small";
import {setDocumentTitle} from "../../util/util";

import {EyeIcon, EyeOffIcon} from '@heroicons/react/solid'
import LocalStorage from "../../util/localStorage";
import moment from "moment";

class LoginView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                username: new Field('username', '', ['empty']),
                password: new Field('password', '', ['empty'])
            },
            page: "",
            isPasswordVisible: false,
            rememberMe: LocalStorage.has("REMEMBER_ME") ? LocalStorage.get("REMEMBER_ME") : false
        };
    }

    componentDidMount() {
        this.props.dispatch(resetUserMessage());
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevProps.user.data === null) && (!!this.props.user.data)) {
            this.props.history.push("/");
        }

        if (this.state.page !== this.props?.location?.pathname) {
            this.setState({
                page: this.props.location.pathname
            }, () => setDocumentTitle(this.props.location.pathname, this.props.translate));
        }
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleEyeClick = () => {
        this.setState({isPasswordVisible: !this.state.isPasswordVisible})
    }

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    submit = (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (!this.state.fields.password.errorMessage && !this.state.fields.username.errorMessage) {
                this.props.dispatch(login({
                    username: this.state.fields.username.value,
                    password: this.state.fields.password.value
                }));
                localStorage.setItem("REMEMBER_ME", this.state.rememberMe)
            }
        });
    };

    toggleRememberMe = () => {
        this.setState({
            rememberMe: !this.state.rememberMe.value
        })
    }

    render() {
        const {translate} = this.props;

        return (
            <Layout {...this.props}>
                <div className="px-6 pb-6 pt-12 sm:px-14 sm:pb-14 sm:pt-24">
                    <div className="theme-dark-popup bg-inverse shadow rounded-lg pt-8 px-8 pb-2 max-w-sm mx-auto">
                        <div className="mx-auto pb-4 pb-6 max-w-sm text-center text-90 text-2xl text-gray-600">
                            Login
                        </div>

                        <div className="mb-6 ">
                            <label className="block font-bold mb-2 text-gray-900"
                                   htmlFor="email">Email</label>
                            <FieldText
                                className="form-control"
                                onChange={this.handleInputChange}
                                handleKeyDown={this.submitForm}
                                {...this.state.fields.username}
                                placeholder={"email@domain.com"}
                                type={"email"}
                            />
                        </div>

                        <div className="mb-6">
                            <label className="block font-bold mb-2 text-gray-900"
                                   htmlFor="email">Password</label>
                            <div className="relative">
                                {!this.state.isPasswordVisible && (
                                    <FieldPassword
                                        handleKeyDown={this.submitForm}
                                        className="form-control"
                                        onChange={this.handleInputChange} {...this.state.fields.password}
                                        placeholder={"Enter your password"}
                                    />
                                )}

                                {!!this.state.isPasswordVisible && (
                                    <FieldText
                                        handleKeyDown={this.submitForm}
                                        className="form-control"
                                        onChange={this.handleInputChange} {...this.state.fields.password}
                                        placeholder={"Enter your password"}
                                    />
                                )}

                                <button
                                    type="link"
                                    onClick={() => this.handleEyeClick()}
                                    className="btn btn-link p-0 w-6 h-6 absolute rounded-full top-2 right-2.5 text-secondary-600 cursor-pointer focus:ring-primary-500"
                                >
                                    {this.state.isPasswordVisible ? <EyeIcon/> : <EyeOffIcon/>}
                                </button>
                            </div>
                        </div>
                        {!this.props.user.isLoading && (
                            <React.Fragment>
                                <div className="">
                                    <div className="">
                                        <button
                                            className="w-full btn btn-primary justify-center"
                                            onClick={this.submit}>
                                            {translate("text.sign_in")}
                                        </button>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <input
                                            onClick={this.toggleRememberMe}
                                            id="remember_me"
                                            name="remember_me"
                                            type="checkbox"
                                            value={this.state.rememberMe.value}
                                            className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"/>
                                        <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                                            Remember me
                                        </label>
                                    </div>

                                    <div className="text-sm">
                                        <Link className="btn btn-link px-0" to={"/reset-password"}>
                                            Forgot Your Password?
                                        </Link>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}

                        {this.props.user.isLoading && (
                            <div className="mt-3 bg-secondary-50 mb-3">
                                <LoaderSmall text="Logging In"/>
                            </div>
                        )}

                        {this.props.user.error && (
                            <div className="px-6 py-3 mt-6 flex rounded-btn bg-red-100 text-red-700 mb-6">
                                <svg className="text-red-700 w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd"
                                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                          clipRule="evenodd"/>
                                </svg>

                                {translate(this.props.user.errorMessage)}
                            </div>
                        )}
                    </div>
                </div>
                <div className="text-gray-900 py-3 px-6 text-center text-sm">
                    <p className="text-center mb-4">
                        Developed in partnership with, <a
                        className="hover:opacity-70 text-primary-700 border-b border-primary-600 uppercase"
                        href="https://lukasa.com/" target="_blank">LUKASA</a>, a modernization business technology
                        consulting firm.
                    </p>
                    <span>©{moment().year()} K:P LABS | All rights reserved.</span>
                </div>
            </Layout>
        );
    }
}

export default connect(state => state)(LoginView);
