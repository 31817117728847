import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util";
import {doneDownload, errorDownload,} from "../actions/download";
import {doneGetResource} from "../actions/resource";

export function* downloadCall(action) {
    let user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.downloadFile, user, action.data.query, action.data.resource);
    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneDownload(result.data));
            }
        } else {
            yield put(doneDownload(result.data));
        }
    } else {
        yield put(errorDownload(result.data));
    }
}


export function* watchDownload() {
    yield takeLatest('DOWNLOAD', downloadCall);
}

//
// export function* uploadDocumentCall(action) {
//     let user = yield* checkUserHelper(action.data.user);
//
//     let result;
//     if (action.data.files && action.data.files.length > 0) {
//         result = yield call(Api.uploadDocuments, user, action.data.id, action.data.files, action.data.DocumentTypeID, action.data.Descriptions, action.data.resource);
//     }
//
//     if (result && result.status === 0) {
//
//         if (action.data.piggyResource) {
//             const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
//             if (resPiggy && resPiggy.status === 0) {
//                 yield put(doneGetResource(resPiggy.data));
//                 yield put(doneUploadDocument(result.data));
//             }
//         } else {
//             yield put(doneUploadDocument(result.data));
//         }
//         if (action.data.piggySecondResource) {
//             const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggySecondResource);
//             if (resPiggy && resPiggy.status === 0) {
//                 yield put(doneGetSecondResource(resPiggy.data));
//             }
//         }
//
//         yield put(pushNotification(action));
//     } else {
//         yield put(errorUploadDocument(result.data));
//     }
// }
//
// export function* watchUploadDocument() {
//     yield takeLatest('UPLOAD_DOCUMENT', uploadDocumentCall);
// }
//
// export function* deleteDocumentCall(action) {
//     let user = yield* checkUserHelper(action.data.user);
//
//     let result = yield call(Api.deleteResource, user, action.data.params, action.data.resource);
//
//     if (result && result.status === 0) {
//         if (action.data.piggySecondResource) {
//             const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggySecondResource);
//             if (resPiggy && resPiggy.status === 0) {
//                 yield put(doneGetSecondResource(resPiggy.data));
//             }
//         }
//         if (action.data.piggyResource) {
//             const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
//             if (resPiggy && resPiggy.status === 0) {
//                 yield put(doneGetResource(resPiggy.data));
//                 yield put(doneUploadDocument(result.data));
//             }
//         } else {
//             yield put(doneDeleteDocument(result.data));
//         }
//         if (action.data.piggySecondResource) {
//             const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggySecondResource);
//             if (resPiggy && resPiggy.status === 0) {
//                 yield put(doneGetSecondResource(resPiggy.data));
//             }
//         }
//
//         yield put(pushNotification(action));
//     } else {
//         yield put(errorDeleteDocument(result.data));
//     }
// }
//
// export function* watchDeleteDocument() {
//     yield takeLatest('DELETE_DOCUMENT', deleteDocumentCall);
// }
