const downloadReducer = (state = {}, action) => {
    switch (action.type) {
        case 'DOWNLOAD':
            return Object.assign({}, state, {
                data: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_DOWNLOAD':
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_DOWNLOAD':
            return Object.assign({}, state, {
                data: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'UPLOAD_DOCUMENT':
            return Object.assign({}, state, {
                data: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_UPLOAD_DOCUMENT':
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_UPLOAD_DOCUMENT':
            return Object.assign({}, state, {
                data: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'DELETE_DOCUMENT':
            return Object.assign({}, state, {
                deleteDocument: null,
                data: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_DELETE_DOCUMENT':
            return Object.assign({}, state, {
                deleteDocument: true,
                data: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_DELETE_DOCUMENT':
            return Object.assign({}, state, {
                data: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        default:
            return state;
    }
};

export default downloadReducer;
