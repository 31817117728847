import React, {Component, Fragment} from 'react'
import {checkPerm, classNames, getProp, READ_PERM, setDocumentTitle} from "../../util/util";
import LocalStorage from "../../util/localStorage";
import {logoutClear} from "../../data/actions/user";
import {Disclosure, Menu, Transition} from "@headlessui/react";
import {MenuIcon, XIcon} from "@heroicons/react/outline";
import {Link} from "react-router-dom";
import Logout from "../logout";
import Env from "../../util/env";
import Resources from "../../data/services/resources";
import ReactImageFallback from "../image-fallback";
import Notification from "../notification";
import {hideNotification} from "../../data/actions/ui";

export default class LayoutDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logout: false,
            ThemeDark: false,
            sidebarIsCollapsed: true,
            sidebarAnimation: false,
            page: "",
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps?.user?.logout) {
            return {logout: true};
        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.logout) {
            LocalStorage.clearAllExcept([
                'username'
            ]);
            LocalStorage.remove('user');
            this.props.dispatch(logoutClear());
            this.props.history.push("/login");
        }

        if (this.state.page !== window.location.pathname) {
            this.setState({
                page: window.location.pathname
            }, () => setDocumentTitle(window.location.pathname, this.props.translate));
        }
    }

    render() {
        const user = {
            name: LocalStorage.get('user')?.Contact?.FirstName + " " + LocalStorage.get('user')?.Contact?.LastName,
            email: LocalStorage.get('user')?.Contact?.Email,
        }

        const navigation = [
            checkPerm(Resources.Clients, READ_PERM) ? {
                name: 'Clients',
                href: '/clients',
                active: ("/clients" === window.location.pathname)
            } : null,
            checkPerm(Resources.Assessments, READ_PERM) ? {
                name: 'Assessments',
                href: '/assessments',
                active: ("/assessments" === window.location.pathname)
            } : null,
            checkPerm(Resources.Sets, READ_PERM) ? {
                name: 'Sets',
                href: '/sets',
                active: ("/sets" === window.location.pathname)
            } : null,
        ].filter((it) => !!it);

        const userNavigation = [
            checkPerm(Resources.Users, READ_PERM) ? {name: 'Users', href: '/users'} : null,
            {name: 'Logged devices', href: '/devices'},
            {name: 'Settings', href: '/lookup'},
            {name: 'Sign out', href: '/logout'}
        ].filter((it) => !!it);

        const notifications = getProp(this.props, "ui.messages", [
            {message: null}, {message: null}, {message: null}
        ]).map((it) => {
            let msg;
            switch (it.message) {
                case 'CREATE_RESOURCE':
                    msg = "Entry created";
                    break;
                case 'UPDATE_RESOURCE':
                    msg = "Entry updated";
                    break;
                case 'DELETE_RESOURCE':
                    msg = "Entry deleted";
                    break;
                default:
                    msg = "Operation complete";
                    break;
            }
            if (it.isError) msg = this.props.translate(it.message)
            return (
                <Notification
                    show={!!it.message}
                    onClose={() => {
                        this.props.dispatch(hideNotification(it.id))
                    }}
                    title={"Notification"}
                    message={msg}
                    isError={!!it.isError}
                />
            )
        });

        return (
            <>
                <Disclosure as="header" className="bg-white shadow relative">
                    {({open}) => (
                        <>
                            <div
                                className="max-w-screen-3xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
                                <div className="relative h-16 flex justify-between">
                                    <div className="relative z-10 px-2 flex lg:px-0">
                                        <div className="flex-shrink-0 flex items-center">
                                            <Link to="/clients">
                                                <img
                                                    className="block h-12 w-auto"
                                                    src="/images/kp-logo.png?v=1.0.0"
                                                    alt="K:P Assess"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                    <div
                                        className="relative z-0 flex-1 px-2 flex items-center justify-center sm:absolute sm:inset-0">
                                        <nav className="hidden lg:py-2 lg:flex lg:space-x-8" aria-label="Global">
                                            {navigation.map((item) => (
                                                <Link
                                                    key={item.name}
                                                    to={item.href}
                                                    className={classNames(
                                                        item.active ? 'border-primary-600' : 'border-transparent',
                                                        'py-2 h-16 px-3  text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                                                    )}
                                                >
                                                    {item.name}
                                                </Link>
                                            ))}
                                        </nav>
                                    </div>
                                    <div className="relative z-10 flex items-center lg:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button
                                            className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                                            <span className="sr-only">Open menu</span>
                                            {open ? (
                                                <XIcon className="block h-6 w-6" aria-hidden="true"/>
                                            ) : (
                                                <MenuIcon className="block h-6 w-6" aria-hidden="true"/>
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                    <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">

                                        {/* Profile dropdown */}
                                        <Menu as="div" className="flex-shrink-0 relative ml-4">
                                            {({open}) => (
                                                <>
                                                    <div>
                                                        <Menu.Button
                                                            className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kp-blue-light">
                                                            <span className="sr-only">Open user menu</span>
                                                            <div
                                                                className="w-10 h-10 bg-blue-400 rounded-full flex justify-center items-center select-none">

                                                                <ReactImageFallback
                                                                    src={Env.getApiUrl("/api/user/image?token=" + LocalStorage.get('user')?.access_token)}
                                                                    fallbackImage={(<div
                                                                        className="text-inverse font-black">{user?.name.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>)}
                                                                    initialImage={(<div
                                                                        className="text-inverse font-black">{user?.name.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>)}
                                                                    alt={user?.name}
                                                                    className=""
                                                                />
                                                            </div>
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        show={open}
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items
                                                            static
                                                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none"
                                                        >
                                                            {userNavigation.map((item) => (
                                                                <Menu.Item key={item.name}>
                                                                    {({active}) => {
                                                                        if (item.href === '/logout') {
                                                                            return (
                                                                                <Logout
                                                                                    className={classNames(
                                                                                        'block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100'
                                                                                    )}
                                                                                    history={this.props.history}
                                                                                    translate={this.props.translate}
                                                                                    dispatch={this.props.dispatch}
                                                                                />
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <Link
                                                                                    to={item.href}
                                                                                    className={classNames(
                                                                                        active ? 'bg-gray-100' : '',
                                                                                        'block py-2 px-4 text-sm text-gray-700'
                                                                                    )}
                                                                                >
                                                                                    {item.name}
                                                                                </Link>
                                                                            )
                                                                        }
                                                                    }}
                                                                </Menu.Item>
                                                            ))}
                                                        </Menu.Items>
                                                    </Transition>
                                                </>
                                            )}
                                        </Menu>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
                                <div className="pt-2 pb-3 px-2 space-y-1">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="border-t border-gray-200 pt-4 pb-3">
                                    <div className="px-4 flex items-center">
                                        <div className="flex-shrink-0">
                                            <div>
                                                <div
                                                    className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kp-blue-light">
                                                    <span className="sr-only">Open user menu</span>
                                                    <div
                                                        className="w-10 h-10 bg-blue-400 rounded-full flex justify-center items-center select-none">

                                                        <ReactImageFallback
                                                            src={Env.getApiUrl("/api/user/image?token=" + LocalStorage.get('user')?.access_token)}
                                                            fallbackImage={(<div
                                                                className="text-inverse font-black">{user?.name.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>)}
                                                            initialImage={(<div
                                                                className="text-inverse font-black">{user?.name.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>)}
                                                            alt={user?.name}
                                                            className=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-base font-medium text-gray-800">{user.name}</div>
                                            <div className="text-sm font-medium text-gray-500">{user.email}</div>
                                        </div>
                                    </div>
                                    <div className="mt-3 px-2 space-y-1">
                                        {userNavigation.map((item) => {
                                                if (item.href === '/logout') {
                                                    return (
                                                        <Logout
                                                            className={classNames('block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900')}
                                                            history={this.props.history}
                                                            translate={this.props.translate}
                                                            dispatch={this.props.dispatch}/>
                                                    )
                                                } else {
                                                    return (
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                                        >
                                                            {item.name}
                                                        </Link>
                                                    )
                                                }
                                            }
                                        )}
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>

                {!this.props.noDefaultLayout && (
                    <main className="max-w-screen-3xl px-6 py-8 mx-auto pb-10 lg:py-12 lg:px-8">
                        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                            {this.props.children}
                        </div>
                    </main>
                )}

                {!!this.props.noDefaultLayout && (
                    this.props.children
                )}

                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live="assertive"
                    className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-30 top-55"
                >
                    <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                        {notifications}
                    </div>
                </div>
            </>
        )
    }
}
