import React, {Component} from "react";
import {connect} from "react-redux";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {getSecondResource} from "../../data/actions/secondResource";
import {classNames, getProp, toFrontDateTimeFromUTC} from "../../util/util";
import LoaderSmall from "../loader-small";
import FieldSelectSearch from "../field-select-search";
import {Field, FieldsManager} from "../../data/services/fields";

class AssessmentsHistoryDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null,
            dataItems: {},
            fields: {
                ValidTo: new Field('ValidTo', "", ['']),
            }
        };


    }

    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.secondResource.isLoading && !this.props.secondResource.isLoading) {
            let dataItems = {}
            getProp(this.props.secondResource, "data.assessments/history", []).map(item => {
                Object.assign(dataItems, {
                    [item.ValidTo]: item
                })
            })

            this.setState({dataItems});
        }
    }

    fetchData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: {
                id: this.props.id
            },
            resource: Resources.AssessmentsHistory
        }))
    }

    handleInputChange = (name, value) => {
        this.setState({
            selectedItem: this.state.dataItems[value],
            fields: FieldsManager.updateField(this.state.fields, name, value)
        });
    };

    setSelectedItem = (item) => {
        this.setState({
            selectedItem: item,
            fields: FieldsManager.updateField(this.state.fields, "ValidTo", item.ValidTo)
        })
    }

    render() {
        const {translate, close, secondResource} = this.props;

        let tabContent = null;
        let selects = Object.keys(this.state.dataItems).reduce((memo, it) => {
            memo[it] = toFrontDateTimeFromUTC(it);
            return memo;
        }, {});
        let selectedItem = null;

        const data = Object.values(this.state.dataItems).map((item, i) => {
            const isSelected = (this.state.selectedItem && item.CreateUpdateDate === this.state.selectedItem.CreateUpdateDate) || (!this.state.selectedItem && !i);

            if (isSelected) {
                tabContent = (
                    <div className='sticky top-0 grid grid-cols-3 lg:grid-cols-6 gap-4'>
                        <div className="modal-form-control mb-4 col-span-3">
                            <div className="field-label align-top text-secondary-900">Assessment Name</div>
                            <div>{item.AssessmentName}</div>
                        </div>
                        <div className="modal-form-control mb-4">
                            <div className="field-label align-top text-secondary-900">Level</div>
                            <div className=''>{item.AssessmentLevel}</div>
                        </div>

                        <div className="modal-form-control mb-4">
                            <div className="field-label align-top text-secondary-900">Milestone</div>
                            <div className=''>{item.AssessmentMilestone}</div>
                        </div>

                        <div className="modal-form-control mb-4">
                            <div className="field-label align-top text-secondary-900">Try</div>
                            <div className=''>{item.AssessmentTry}</div>
                        </div>

                        <div className="modal-form-control mb-4 col-span-3">
                            <div className="field-label align-top text-secondary-900">Assessment Objective</div>
                            <div className=''>{item.AssessmentObjective}</div>
                        </div>
                        <div className="modal-form-control mb-4 col-span-3">
                            <div className="field-label align-top text-secondary-900">Assessment Example</div>
                            <div className=''>{item.AssessmentExample}</div>
                        </div>

                        <div className="modal-form-control mb-4 col-span-3">
                            <div className="field-label align-top text-secondary-900">Assessment Scoring 1/2</div>
                            <div className=''>{item.AssessmentScoringHalf}</div>
                        </div>

                        <div className="modal-form-control mb-4 col-span-3">
                            <div className="field-label align-top text-secondary-900">Assessment Scoring 1</div>
                            <div className=''>{item.AssessmentScoringOne}</div>
                        </div>

                    </div>
                )
            }

            return (
                <div className='my-2 w-100'>
                    <div
                        className={classNames('relative hover:bg-white hover:text-secondary-900 cursor-pointer mb-15 px-2 py-1 border border-l-0 border-r-0 z-20', isSelected ? "selected-history bg-white border-secondary-600 " : "border-transparent")}
                        onClick={() => this.setSelectedItem(item)}
                    >
                        {toFrontDateTimeFromUTC(item.ValidTo)}
                    </div>
                </div>
            )
        })

        return (
            <React.Fragment>
                {!secondResource.isLoading && data.length > 0 && (
                    <div className="sm:hidden mt-5">
                        <FieldSelectSearch
                            values={selects}
                            onChange={this.handleInputChange}
                            {...this.state.fields.ValidTo}
                            value={this.state.fields.ValidTo.value}
                            addClass={"form-control"}
                        />
                    </div>
                )}

                {!secondResource.isLoading && data.length > 0 && (
                    <div className="flex">
                        <div
                            className="hidden sm:block border border-secondary-600 border-r-0 mb-5 mt-5 w-40 flex-grow-1 rounded-tl-md rounded-bl-md bg-secondary-50">
                            {!secondResource.isLoading && data}
                        </div>
                        <div
                            className="mb-5 mt-5 sm:w-[calc(100%-10rem)] border border-secondary-600 px-5 py-4 rounded-tr-md rounded-br-md sm:rounded-tl-none sm:rounded-bl-none rounded-md">
                            {tabContent}
                        </div>
                    </div>
                )}

                {!!secondResource.isLoading && <LoaderSmall/>}
                {!secondResource.isLoading && data.length === 0 && "No record"}
                <div className="pt-5">
                    <div className="flex justify-end">
                        <button type="button"
                                onClick={close}
                                className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer">{translate("btn.close")}
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(state => state)(AssessmentsHistoryDialog);
