import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Disclosure} from "@headlessui/react";

export default class PublicLayout extends Component {

    getPageName = () => {
        switch (this.props.match.path) {
            case "/reset-password":
                return "Reset Password";
            case "/":
            case "/login":
                return "K:P Assess";
            case "/reset-password-confirm/:email/:token/:is_new":
            case "/reset-password-confirm/:email/:token":
                return "Set Password";
            default:
                return "Page not found"
        }
    }

    render() {
        const {translate, history, dispatch} = this.props
        return (
            <React.Fragment>
                <div className="bg-gray-50 h-screen text-default">
                    <Disclosure as="header" className="bg-white shadow">
                        {({open}) => (
                            <>
                                <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
                                    <div className="relative h-16 flex justify-between">
                                        <div className="relative z-10 px-2 flex lg:px-0">
                                            <div className="flex-shrink-0 flex items-center">
                                                <Link to="/">
                                                    <img
                                                        className="block h-12 w-auto"
                                                        src="/images/kp-logo.png?v=1.0.0"
                                                        alt="K:P Assess"
                                                    />
                                                </Link>
                                            </div>
                                        </div>

                                        <div
                                            className="relative z-0 flex-1 px-2 flex items-center justify-center sm:absolute sm:inset-0"
                                        >
                                            <h1 className="text-4xl text-gray-600">
                                                {this.getPageName()}
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </Disclosure>

                    <div>
                        {this.props.children}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
