import React, {Component} from "react";
import {connect} from "react-redux";
import Dialog from '../../components/dialog'
import Button from "../button";

class ConfirmDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {translate, close} = this.props;
        return (
            <Dialog
                visible={true}
                onClose={close}
                translate={translate}
                modalTitle={this.props.modalTitle}
            >
                <div className="text-center">
                    {this.props.modalText}
                </div>

                <div className="pt-5">
                    <div className="flex justify-end">
                        <Button
                            type="outline"
                            className="btn btn-outline focus:ring-offset-inverse"
                            onClick={this.props.close}
                        >
                            {translate("btn.cancel")}
                        </Button>
                        <Button
                                className="btn btn-primary ml-3 focus:ring-offset-inverse"
                                onClick={this.props.submit}
                        >
                            {this.props.submitTxt ? this.props.submitTxt : translate("btn.confirm")}
                        </Button>

                    </div>
                </div>
            </Dialog>
        );
    }
}

export default connect(state => state)(ConfirmDialog);
