import React, {Component} from "react";
import PublicLayout from "../../components/layout";
import {Link} from "react-router-dom";
import connect from "react-redux/es/connect/connect";

class NotFoundView extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {translate} = this.props;

        return (
            <PublicLayout {...this.props}>
                <div className="pt-24 px-14 pb-7 layout-min-h">
                    <div className="mx-auto py-8 max-w-sm text-center text-90">
                        <div>
                            <h1 className="text-6xl mb-6">{translate("page.404.heading_main")}</h1>

                            <p className="mb-8 text-soft">
                                {translate("page.404.body")}
                            </p>

                            <Link to="/"
                                  className="btn btn-primary">{translate("btn.back_to_home")}</Link>
                        </div>
                    </div>
                </div>
            </PublicLayout>
        );
    }
}


export default connect(state => state)(NotFoundView);