export function getCustomStyles() {
    return {
        control: (provided, state) => ({
            ...provided,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            height: "2.25rem",
            transition: "none",
            borderRadius: "var(--rounded-btn)",
            minHeight: "38px",
            color: "var(--text-secondary-700)",
            background: "var(--color-inverse)",
            boxShadow: state.isFocused ? "var(--ring-primary)" : 0,
            border: state.isFocused ? "1px solid transparent" : "1px solid var(--color-gray-300)",
            '&:hover': {
                border: state.isFocused ? "1px solid transparent" : "1px solid var(--color-gray-300)",
            }
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isFocused ? '#fff' : "var(--text-secondary-700)",
            fontWeight: state.isSelected ? 700 : '',
            background: state.isFocused ? "var(--color-kp-blue)" : "transparent",
            cursor: "pointer",
            '&:hover': {
                color: '#fff',
                background: "var(--color-kp-blue-dark)"
            }
        }),
        input: (provided, state) => ({
            ...provided,
            color: "var(--color-secondary-700)"
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            backgroundColor: "var(--color-gray-300)"
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
            color: "var(--color-gray-400)"
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: "var(--color-gray-400)"
        })
    }
}