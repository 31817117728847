import React, {Component} from "react";
import {connect} from "react-redux";
import LoaderSmall from "../../components/loader-small";
import {createResource, deleteResource, getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {checkPerm, CREATE_PERM, currentDate, getProp,} from "../../util/util";
import {download} from "../../data/actions/download";
import LayoutDashboard from "../../components/layout-dashboard";
import PageHeading, {GoBackIcon} from "../../components/page-heading";
import SimpleTable from "../../components/simple-table";
import {Field} from "../../data/services/fields";
import ModalConfirm from "../../components/modal/modal-confirm";
import ModalSaveResource from "../../components/modal/modal-save-resource";

class ReportDocumentsView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            limit: 10,
            offset: 0,
            sort: "ASC",
            sortBy: "",
            createDocumentReportDialog: false
        };
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            resource: Resources.ReportDocuments,
            query: this.getQuery()
        }))
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            ClientID: this.props.match.params.id
        }
    }

    returnSortIcons = (value) => {
        if (this.state.sortBy === value) {
            if ((this.state.sort === "DESC")) {
                return (
                    <div className="up-n-down ml-10 color-white">
                        <i className="fas fa-chevron-up opacity-0-4 color-white"/>
                        <i className="fas fa-chevron-down color-white"/>
                    </div>
                )
            } else {
                return (
                    <div className="up-n-down ml-10 color-white">
                        <i className="fas fa-chevron-up color-white"/>
                        <i className="fas fa-chevron-down opacity-0-4 color-white"/>
                    </div>
                )
            }
        } else {
            return (
                <div className="up-n-down ml-10 color-white">
                    <i className="fas fa-chevron-up color-white"/>
                    <i className="fas fa-chevron-down color-white"/>
                </div>
            )
        }
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, this.fetchData);
    }

    downloadDocument = (item) => {
        this.props.dispatch(download({
            user: LocalStorage.get("user"),
            resource: Resources.ReportDocumentsPDF,
            query: Object.assign({
                ClientID: this.props.match.params.id,
                ReportDocumentID: item.ReportDocumentID,
                format: "pdf",
                name: "document_" + currentDate() + "." + "pdf"
            })
        }))
    }

    deleteDocument = () => {
        this.props.dispatch(deleteResource({
            user: LocalStorage.get("user"),
            query: Object.assign({
                id: this.state.selectedItem.ReportDocumentID,
            }),
            resource: Resources.ReportDocumentsPDF,
            piggyQuery: this.getQuery(),
            piggyResource: Resources.ReportDocuments
        }))
        this.hideConfirmDialog()
    }

    CreateDocumentReportDialog = (params) => {
        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            resource: Resources.ReportDocumentsPDF,
            params: Object.assign({
                ClientID: this.props.match.params.id,
                ...params
            }),
            query: this.getQuery(),
            piggyResource: Resources.ReportDocuments
        }))
        this.closeCreateDocumentReportDialog()
    }

    showConfirmDialog = item => this.setState({confirmDialog: true, selectedItem: item});
    hideConfirmDialog = () => this.setState({confirmDialog: false, selectedItem: null});

    closeCreateDocumentReportDialog = () => this.setState({createDocumentReportDialog: false})

    getFields = () => {
        return {
            Creator: new Field("Creator", '', ['empty']),
            Description: new Field("Description", '', ['empty']),
            DocumentDate: new Field("DocumentDate", '', ['empty'], false, 'datetimezone'),
        }
    }

    getDialogFields = () => {
        return {
            Date: new Field("Date", '', ['empty'], false, 'date'),
            Description: new Field("Description", '', [], false, 'textarea')
        }
    }

    toggleConfirmDialog = (item = this.state.selectedItem) => {
        this.setState({confirmDialog: !this.state.confirmDialog, selectedItem: item});
    }

    render() {
        const {translate, resource} = this.props;
        const data = getProp(resource.data, "report", [])
        const count = getProp(resource.data, "count", 0)

        return (
            <LayoutDashboard {...this.props} >
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12 relative">
                    <PageHeading
                        title={<h1 className='color-dark-blue'>
                            <GoBackIcon
                                onClick={() => this.props.history.push("/report/" + this.props.match.params.id)}
                            />
                            {translate("page.title.Documents")}
                        </h1>}
                        titleClass="pb-1"
                    >
                        {
                            checkPerm(Resources.ReportDocuments, CREATE_PERM) && (
                                <button
                                    className="btn btn-outline ml-3"
                                    onClick={() => this.setState({createDocumentReportDialog: true})}
                                >
                                    {translate("btn.create")}
                                </button>
                            )
                        }
                    </PageHeading>

                    {!this.props.resource.isLoading && (
                        <SimpleTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={resource.isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onDelete={(item) => this.toggleConfirmDialog(item)}
                            customActions={
                                [{
                                    action: (item) => this.downloadDocument(item),
                                    label: translate("text.Download"),
                                    icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                               viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"/>
                                    </svg>
                                }]
                            }
                        />
                    )}
                    {this.props.resource.isLoading && (
                        <LoaderSmall/>
                    )}
                    {(data.length === 0) && !resource.isLoading && (
                        <div className="margin-auto text-center mt-10">
                            {translate("text.no_records")}
                        </div>
                    )}

                    {!!this.state.confirmDialog && (
                        <ModalConfirm
                            visible={true}
                            title={translate("text.delete_document")}
                            text={translate("text.are_you_sure_you_want_to_delete")}
                            onClose={this.hideConfirmDialog}
                            onConfirm={this.deleteDocument}
                        />
                    )}

                    {!!this.state.createDocumentReportDialog && (
                        <ModalSaveResource
                            title={(this.state.selectedItem ? "Edit" : "Save") + " document"}
                            widthClass="max-w-lg"
                            visible={!!this.state.createDocumentReportDialog}
                            onClose={this.closeCreateDocumentReportDialog}
                            blurBackdrop={true}
                            fields={this.getDialogFields()}
                            disableOverflow
                            onSubmit={(params) => {
                                if (params) {
                                    this.CreateDocumentReportDialog(params)
                                }
                            }}
                            translate={this.props.translate}
                        />
                    )}
                    {/* TODO: Delete later*/}
                    {/*{!!this.state.createDocumentReportDialog && (*/}
                    {/*    <CreateDocumentReportDialog*/}
                    {/*        translate={translate}*/}
                    {/*        submit={this.CreateDocumentReportDialog}*/}
                    {/*        close={this.closeCreateDocumentReportDialog}*/}
                    {/*    />*/}
                    {/*)}*/}
                </div>
            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(ReportDocumentsView);
