import React, {Component} from "react";
import {connect} from "react-redux";
import {Field, FieldsManager} from "../../data/services/fields";
import FieldTextarea from "../field-textarea";
import FieldText from "../field-text";
import Resources from "../../data/services/resources";
import LocalStorage from "../../util/localStorage";
import {createResource, updateResource} from "../../data/actions/resource";
import FieldSelectSearch from "../field-select-search";
import FieldDropdownSelect from "../field-dropdown-select";
import axios from "axios";
import Env from "../../util/env";
import {processResponse} from "../../data/services/api-util";
import {getLookup} from "../../util/util";

class AssessmentsDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            minimumNumberOfImages: 0,
            canSubmit: false

        };
    }

    getFields = () => {
        return {
            AssessmentTypeID: new Field('AssessmentTypeID', this.props.selectedItem ? this.props.selectedItem.AssessmentTypeID : "", ['empty']),
            AssessmentName: new Field('AssessmentName', this.props.selectedItem ? this.props.selectedItem.AssessmentName : "", ['empty']),
            AssessmentDescription: new Field('AssessmentDescription', this.props.selectedItem ? this.props.selectedItem.AssessmentDescription : "", []),
            AssessmentLevel: new Field('AssessmentLevel', this.props.selectedItem ? this.props.selectedItem.AssessmentLevel : "", ['empty', 'positive_integer']),
            AssessmentMilestone: new Field('AssessmentMilestone', this.props.selectedItem ? this.props.selectedItem.AssessmentMilestone : "", ['empty']),
            AssessmentExample: new Field('AssessmentExample', this.props.selectedItem ? this.props.selectedItem.AssessmentExample : "", ['empty']),
            AssessmentObjective: new Field('AssessmentObjective', this.props.selectedItem ? this.props.selectedItem.AssessmentObjective : "", ['empty']),
            AssessmentScoringOne: new Field('AssessmentScoringOne', this.props.selectedItem ? this.props.selectedItem.AssessmentScoringOne : "", ['empty']),
            AssessmentScoringHalf: new Field('AssessmentScoringHalf', this.props.selectedItem ? this.props.selectedItem.AssessmentScoringHalf : "", ['empty']),
            AssessmentScoringZero: new Field('AssessmentScoringZero', this.props.selectedItem ? this.props.selectedItem.AssessmentScoringZero : "", []),
            AssessmentTry: new Field('AssessmentTry', this.props.selectedItem ? this.props.selectedItem.AssessmentTry : "0", []),
            AssessmentImagesQuantity: new Field('AssessmentImagesQuantity', this.props.selectedItem ? this.props.selectedItem.AssessmentImagesQuantity : "0", ['empty']),
            AssessmentOperantID: new Field('AssessmentOperantID', this.props.selectedItem ? {
                value: this.props.selectedItem.AssessmentOperantID,
                label: this.props.selectedItem.Operant
            } : "", ['empty']),
            SetID: new Field('SetID', this.props.selectedItem ? this.props.selectedItem.SetID : 4, []),
        }
    }

    submit = (event) => {
        event && event.preventDefault();
        if (this.state.currQuestionType == 3) {
            this.handleInputChange("AssessmentImagesQuantity", "0")
            this.handleInputChange("AssessmentTry", "1")
        }
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                if (this.props.selectedItem) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get("user"),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                            SetID: typeof this.state.fields.SetID.value == "object" ? this.state.fields.SetID.value.value : this.state.fields.SetID.value,
                            AssessmentOperantID: this.state.fields.AssessmentOperantID.value.value,
                            AssessmentID: this.props.selectedItem.AssessmentID,
                        }),
                        query: this.props.query,
                        resource: Resources.Assessments,
                        piggyResource: Resources.Assessments,
                    }));
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get("user"),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                            AssessmentOperantID: this.state.fields.AssessmentOperantID.value.value,
                            SetID: this.state.fields.SetID.value.value
                        }),
                        query: this.props.query,
                        resource: Resources.Assessments,
                        piggyResource: Resources.Assessments,
                    }));
                }
                this.props.close()
            }
        });
    }

    handleInputChange = (name, value) => {
        this.setState({canSubmit: true})
        if (name === "AssessmentImagesQuantity") {
            if (value == "" || value == "0") {
                this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)})
            }
            if (value <= this.state.currentNumber && value >= this.state.minimumNumberOfImages) {
                this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)})
            }
        } else {
            if (this.state.fields.SetID && value.value != this.state.fields.SetID.value && name == "SetID") {
                this.setState({fields: FieldsManager.updateField(this.state.fields, "AssessmentImagesQuantity", "")})
            }
            this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)}, () => {
                if (name == "SetID") {
                    this.state.currentSets.map(elem => {
                        if (elem.SetID == this.state.fields.SetID.value.value) {
                            this.setState({
                                currQuestionType: elem.QuestionTypeID,
                                currentNumber: elem.QuestionContent?.length,
                                minimumNumberOfImages: elem?.CorrectAnswers + 1
                            }, () => {
                                // VIDEO je QuestionTypeID = 1
                                if (this.state.currQuestionType == 1) {
                                    this.handleInputChange("AssessmentImagesQuantity", 1)
                                    this.handleInputChange("AssessmentTry", 1)
                                }
                                // SLIKA je  QuestionTypeID = 2
                                if (this.state.currQuestionType == 2) {
                                    this.handleInputChange("AssessmentImagesQuantity", elem.QuestionContent?.length)
                                    this.handleInputChange("AssessmentTry", "1")
                                }
                                // NOT APPLICABLE je QuestionTypeID = 3
                                if (this.state.currQuestionType == 3) {
                                    this.handleInputChange("AssessmentImagesQuantity", "0")
                                    this.handleInputChange("AssessmentTry", "1")
                                }
                            })
                        }
                    })

                }
            });
        }
    };

    render() {
        const {translate, close} = this.props;

        const AssessmentType = getLookup('AssessmentType', 'AssessmentTypeID', 'AssessmentType');
        return (
            <React.Fragment>
                <div className="">
                    <div className='mb-3'>
                        <div className='field-label'>{translate("field.AssessmentName")}*</div>
                        <FieldText
                            onChange={this.handleInputChange}
                            {...this.state.fields.AssessmentName}
                            addClass={"form-control"}
                        />
                    </div>
                </div>
                <div className="grid-50-50">
                    <div className='mb-3'>
                        <div className='field-label'>{translate("field.TypeID")}*</div>
                        <FieldSelectSearch
                            addClass={"form-control"}
                            values={AssessmentType}
                            onChange={this.handleInputChange}
                            {...this.state.fields.AssessmentTypeID}/>
                    </div>
                    <div className='mb-3'>
                        <div className="field-label">{translate("field.AssessmentOperant")}*</div>
                        <FieldDropdownSelect
                            onChange={this.handleInputChange}
                            {...this.state.fields.AssessmentOperantID}
                            defaultOptions={true}
                            loadOptions={
                                (inputValue, callback) => {
                                    axios.get(
                                        Env.getApiUrl("api/" + Resources.Lookup, {query: inputValue}),
                                        {
                                            headers: {
                                                'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                            }
                                        }
                                    )
                                        .then((response) => {
                                            const result = processResponse(response);
                                            if (result && result.status === 0) {
                                                const list = result.data.Operant?.map((it) => {
                                                    if (it.OperantID == this.state.fields.AssessmentOperantID.value?.value) {
                                                        this.setState({
                                                            fields: FieldsManager.updateField(this.state.fields, "AssessmentOperantID", {
                                                                label: it.Operant,
                                                                value: it.OperantID
                                                            })
                                                        })
                                                    }
                                                    return {label: it.Operant, value: it.OperantID};
                                                });
                                                callback(list);
                                            }
                                        })
                                }
                            }
                        />
                    </div>
                </div>
                <div className="grid-50-50">
                    <div className='mb-3'>
                        <div className="field-label align-top">{translate("field.AssessmentExample")}*</div>
                        <FieldTextarea
                            onChange={this.handleInputChange}
                            {...this.state.fields.AssessmentExample}
                            addClass={"form-control w-100"}
                        />
                    </div>
                    <div className='mb-3'>
                        <div className="field-label align-top">{translate("field.AssessmentObjective")}*</div>
                        <FieldTextarea
                            onChange={this.handleInputChange}
                            {...this.state.fields.AssessmentObjective}
                            addClass={"form-control w-100"}
                        />
                    </div>
                </div>
                <div className='mb-3'>
                    <div className='field-label'>{this.props.translate("field.Set")}</div>
                    <FieldDropdownSelect
                        onChange={this.handleInputChange}
                        {...this.state.fields.SetID}
                        defaultOptions={true}
                        loadOptions={
                            (inputValue, callback) => {
                                axios.get(
                                    Env.getApiUrl("api/" + Resources.Sets, {
                                        query: inputValue,
                                        notApplicable: 1,
                                        sortBy: "UserID",
                                        sort: "ASC"
                                    }),
                                    {
                                        headers: {
                                            'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                        }
                                    }
                                )
                                    .then((response) => {
                                        const result = processResponse(response);
                                        if (result && result.status === 0) {
                                            this.setState({currentSets: result.data.list})
                                            const filtered = result.data.list
                                                .filter(it => (it.QuestionContent && it.QuestionContent.length > 0) || (it.QuestionTypeID == 3))
                                                .map((it) => {
                                                    if (it.SetID == this.state.fields.SetID.value) {
                                                        if (it.QuestionTypeID == 3) {
                                                            this.setState({
                                                                currentNumber: it.QuestionContent?.length,
                                                                currQuestionType: it.QuestionTypeID,
                                                                fields: FieldsManager.updateField(this.state.fields, "SetID", {
                                                                    label: it.SetName,
                                                                    value: it.SetID
                                                                })
                                                            })
                                                        } else {
                                                            this.setState({
                                                                currentNumber: it.QuestionContent?.length,
                                                                currQuestionType: it.QuestionTypeID,
                                                                fields: FieldsManager.updateField(this.state.fields, "SetID", {
                                                                    label: it.SetName,
                                                                    value: it.SetID
                                                                })
                                                            })
                                                        }
                                                    }
                                                    return {
                                                        label: `${it.SetName}`,
                                                        value: it.SetID
                                                    };
                                                });
                                            callback(filtered);
                                        }
                                    })
                            }
                        }
                        value={{
                            label: this.state.fields.SetID ? this.state.fields.SetID.value.label : "",
                            value: this.state.fields.SetID ? this.state.fields.SetID.value.value : ""
                        }}
                    />
                </div>
                <div className="grid-50-50">
                    {!!this.state.currQuestionType && this.state.currQuestionType == 2 && (
                        <div className='mb-3'>
                            <div className='field-label'>{this.props.translate("field.ReportImagesQuantity")}</div>
                            <div className='flex justify-start items-center'>
                                <FieldText
                                    type={"number"}
                                    onChange={this.handleInputChange}
                                    {...this.state.fields.AssessmentImagesQuantity}
                                    addClass={"form-control"}
                                    placeholder={`${this.props.translate("text.min")} ${this.state.minimumNumberOfImages}`}
                                />
                                {!!this.state.currentNumber &&
                                    <span className='ml-10'>/{this.state.currentNumber}</span>}
                            </div>
                        </div>
                    )}
                    {!!this.state.currQuestionType && this.state.currQuestionType == 2 && (
                        <div className='mb-3'>
                            <div className='field-label'>{this.props.translate("field.ReportTry")}</div>
                            <FieldText
                                type={"number"}
                                onChange={this.handleInputChange}
                                {...this.state.fields.AssessmentTry}
                                addClass={"form-control"}
                            />
                        </div>
                    )}
                </div>
                <div className="grid-50-50">
                    <div className='mb-3'>
                        <div className="field-label">{translate("field.Level")}*</div>
                        <FieldSelectSearch
                            values={{1: 1, 2: 2, 3: 3}}
                            onChange={this.handleInputChange}
                            {...this.state.fields.AssessmentLevel}
                            addClass={"form-control"}
                        />
                    </div>
                    <div className='mb-3'>
                        <div className="field-label">{translate("field.Milestone")}*</div>
                        <FieldSelectSearch
                            values={{
                                1: 1,
                                2: 2,
                                3: 3,
                                4: 4,
                                5: 5,
                                6: 6,
                                7: 7,
                                8: 8,
                                9: 9,
                                10: 10
                            }}
                            onChange={this.handleInputChange}
                            {...this.state.fields.AssessmentMilestone}
                            addClass={"form-control"}
                        />
                    </div>
                </div>
                <div className="grid-50-50">
                    <div className='mb-3'>
                        <div className="field-label">{translate("field.AssessmentScoringZero")}</div>
                        <FieldTextarea
                            onChange={this.handleInputChange}
                            {...this.state.fields.AssessmentScoringZero}
                            addClass={"form-control w-100"}
                        />
                    </div>
                    <div className='mb-3'>
                        <div className="field-label">{translate("field.AssessmentScoringHalf")}*</div>
                        <FieldTextarea
                            onChange={this.handleInputChange}
                            {...this.state.fields.AssessmentScoringHalf}
                            addClass={"form-control w-100"}
                        />
                    </div>
                </div>
                <div className="grid-50-50">
                    <div className='mb-3'>
                        <div className="field-label">{translate("field.AssessmentScoringOne")}*</div>
                        <FieldTextarea
                            onChange={this.handleInputChange}
                            {...this.state.fields.AssessmentScoringOne}
                            addClass={"form-control w-100"}
                        />
                    </div>
                    <div className='mb-3'>
                        <div className="field-label">{translate("field.Instruction")}</div>
                        <FieldTextarea
                            onChange={this.handleInputChange}
                            {...this.state.fields.AssessmentDescription}
                            addClass={"form-control w-100"}
                        />
                    </div>
                </div>
                <div className="pt-5">
                    <div className="flex justify-end">
                        <button type="button"
                                onClick={close}
                                className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer">{translate("btn.cancel")}
                        </button>
                        <button type="submit"
                                onClick={this.submit}
                                className="btn btn-primary ml-3 focus:ring-offset-inverse hover:cursor-pointer">{translate("btn.submit")}
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(state => state)(AssessmentsDialog);
