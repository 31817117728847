import React, {Component} from "react";
import {connect} from "react-redux";
import LoaderSmall from "../../components/loader-small";
import {deleteResource, getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {checkPerm, CREATE_PERM, DELETE_PERM, getLookup, getProp, UPDATE_PERM} from "../../util/util";
import {FieldsManager} from "../../data/services/fields";
import ConfirmDialog from "../../components/confirmDialog";
import Tippy from "@tippyjs/react";
import LookupDialog from "../../components/lookupDialog";

class LookupsTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            limit: 10,
            paginationPage: 1,
            offset: 0,
            sort: 'ASC',
            sortBy: "",
            confirmDialog: false,
            addNewClient: false,
            clientItem: {},
            deleteID: null,
            input: "",
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevState.sort !== this.state.sort) || (prevState.archived !== this.state.archived) || (prevState.limit !== this.state.limit) || (prevState.sortBy !== this.state.sortBy && this.state.sortBy !== "")) {
            this.fetchData();
        }
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {},
            resource: Resources.Lookup
        }))
    };

    getQuery = () => {
        return {
            limit: this.state.limit,
            query: this.state.input,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            archived: this.state.archived ? 1 : 0,
        }
    }

    updateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        })
    };

    updateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, this.fetchData);
    }

    handleFilterChange = (name, val) => {
        this.setState({
            [name]: val,
            offset: 0,
            paginationPage: 1,
        }, this.fetchData)
    }

    setActiveTab = (name) => {
        this.setState({activeTab: name})
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    editClient = (item) => {
        this.setState({clientItem: item, addNewClient: true})
    }

    createNewLookupHandler = item => {
        this.setState({LookupKey: item, addNewLookup: true})
    }

    hideAddNewLookup = () => this.setState({LookupKey: null, addNewLookup: false, LookupValue: null, LookupID: null})
    hideConfirmDialog = () => this.setState({LookupKey: null, confirmDialog: false, LookupValue: null, LookupID: null})

    editLookupHandler = (key, value, id) => this.setState({
        LookupKey: key,
        addNewLookup: true,
        LookupValue: value,
        LookupID: id
    })

    showConfirmDialog = (key, value, id) => this.setState({
        LookupKey: key,
        confirmDialog: true,
        LookupValue: value,
        LookupID: id
    })

    deleteLookupHandler = () => {
        this.props.dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: {
                Id: this.state.LookupID,
                Lookup: this.state.LookupKey,
            },
            resource: Resources.Lookup,
            piggyResource: Resources.Lookup
        }))
        this.hideConfirmDialog()
    }

    render() {
        const {translate, resource} = this.props;
        const data = getProp(resource, "data", {})
        const dataItems = Object.keys(data).map((item, i) => {
            if (item == "Location" || item == "Operant") {
                return (
                    <>
                        <tr className={'border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 h-12 overflow-scroll cursor-pointer bg-blue-green-04'}>
                            <td>{translate(`text.${item}`)} <i onClick={() => {
                                this.setState({[item]: !this.state[item]})
                            }} className={`fas fa-${!this.state[item] ? "plus" : "minus"}`}/></td>
                            <td></td>
                            <td>
                                {
                                    checkPerm(Resources.Lookup, CREATE_PERM) && (
                                        <Tippy content={<span>{translate("text.add_new")}</span>}>
                                            <i onClick={() => this.createNewLookupHandler(item)}
                                               className="fas fa-plus-circle"/>
                                        </Tippy>
                                    )
                                }
                            </td>
                        </tr>
                        {!!this.state[item] && Object.keys(getLookup(`${item}`, `${item}ID`, `${item}`)).map(elem => {

                            return (
                                (
                                    <tr className='overflow-scroll border-secondary-200 border hover:bg-gray-200 p-3 border-b-0 h-12 '>
                                        <td></td>
                                        <td>{getLookup(`${item}`, `${item}ID`, `${item}`)[elem]}</td>
                                        <td>
                                            {
                                                checkPerm(Resources.Lookup, UPDATE_PERM) && (
                                                    <Tippy content={<span>{translate("text.Update")}</span>}>
                                                        <i
                                                            onClick={() => this.editLookupHandler(item, getLookup(`${item}`, `${item}ID`, `${item}`)[elem], elem)}
                                                            className="fas fa-edit"/>
                                                    </Tippy>
                                                )
                                            }
                                            {
                                                checkPerm(Resources.Lookup, DELETE_PERM) && (
                                                    <Tippy content={<span>{translate("text.Delete")}</span>}>
                                                        <i
                                                            onClick={() => this.showConfirmDialog(item, getLookup(`${item}`, `${item}ID`, `${item}`)[elem], elem)}
                                                            className={"far fa-times-circle ml-10"}/>
                                                    </Tippy>
                                                )
                                            }
                                        </td>
                                    </tr>
                                )
                            )
                        })}
                    </>
                )
            }
        })

        return (
            <React.Fragment>
                {!this.props.resource.isLoading && (
                    <div className="view">
                        <div className="wrapper">
                            <table className='w-full min-w-full divide-y divide-secondary-200'>
                                <thead>
                                <tr className=''>
                                    <th>
                                        <div
                                            className="px-4 py-2 bg-kp-blue-dark text-left text-xs font-medium text-white uppercase tracking-wider">
                                            {translate("table.Lookup")}
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            className="px-4 py-2 bg-kp-blue-dark text-left text-xs font-medium text-white uppercase tracking-wider">
                                            {translate("table.Value")}
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            className="px-4 py-2 bg-kp-blue-dark text-left text-xs font-medium text-white uppercase tracking-wider">
                                            {translate("table.Actions")}
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                {dataItems}
                            </table>
                        </div>
                    </div>
                )}
                {this.props.resource.isLoading && (
                    <LoaderSmall/>
                )}

                {!!this.state.addNewLookup && (
                    <LookupDialog
                        close={this.hideAddNewLookup}
                        modalTitle={`${translate(this.state.LookupValue ? "text.update" : "text.new")} ${translate(`text.${this.state.LookupKey}`)}`}
                        translate={translate}
                        LookupKey={this.state.LookupKey}
                        LookupValue={this.state.LookupValue}
                        LookupID={this.state.LookupID}
                    />
                )}
                {!!this.state.confirmDialog && (
                    <ConfirmDialog
                        submit={this.deleteLookupHandler}
                        close={this.hideConfirmDialog}
                        modalTitle={translate("text.delete_client")}
                        modalText={`${translate("text.are_you_sure_you_want_to_delete")} ${this.state.LookupValue} from ${translate(`text.${this.state.LookupKey}`)}?`}
                        translate={translate}
                        item={this.state.clientItem}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default connect(state => state)(LookupsTab);
