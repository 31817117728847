import React, {Component} from "react";
import {connect} from "react-redux";
import Dialog from '../../components/dialog'
import {Field, FieldsManager} from "../../data/services/fields";
import FieldText from "../field-text";
import FieldTextarea from "../field-textarea";
import Resources from "../../data/services/resources";
import LocalStorage from "../../util/localStorage";
import {createResource, updateResource} from "../../data/actions/resource";
import FieldDropdownSelect from "../field-dropdown-select";
import axios from "axios";
import Env from "../../util/env";
import {processResponse} from "../../data/services/api-util";
import {fieldsSearchToHtml, getProp} from "../../util/util";
import FieldCreatableSelect from "../field-creatable-async";

class ReportDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            fields: this.getFields(),
            searchFields: this.getSearchFields(),
            minimumNumberOfImages: 0,
            DefaultMilestone: true
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource !== this.props.resource && !!this.props.quizzStart && !this.props.retake) {
            let setID = typeof this.state.fields.SetID.value == "object" ? this.state.fields.SetID.value.value : this.state.fields.SetID.value;
            getProp(this.props.resource.data, "list", []).forEach(item => {
                if (item.SetID == setID) {
                    if (JSON.stringify(this.props.clientItem) != JSON.stringify(item)) {
                        this.props.history.push(`/report/start/${this.props.clientItem.ReportID}`);
                    }
                }
            })
        }
        if (prevProps.resource !== this.props.resource && !!this.props.quizzStart && !!this.props.retake && this.props.resource?.create?.id) {
            this.props.history.push(`/report/start/${this.props.resource?.create?.id}`);
        }
    }

    getFields = () => {
        if (!!this.props.clientItem && this.props.clientItem.ReportStatusID == 1) {
            return {
                ReportID: new Field('ReportID', this.props.clientItem.ReportID, ['empty']),
                SetID: new Field('SetID', this.props.clientItem.SetID, ['empty']),
                ReportStatusID: new Field('ReportStatusID', this.props.clientItem.ReportStatusID, ['empty']),
                ReportImagesQuantity: new Field('ReportImagesQuantity', this.props.clientItem.ReportImagesQuantity, ['empty']),
                ReportTry: new Field('ReportTry', this.props.clientItem.ReportTry, ['empty']),
            }
        }
        if (!!this.props.clientItem && this.props.clientItem.ReportStatusID == 3) {
            return {
                ReportID: new Field('ReportID', this.props.clientItem.ReportID, ['empty']),
                ReportStatusID: new Field('ReportStatusID', this.props.clientItem.ReportStatusID, ['empty']),
                ReportComment: new Field('ReportComment', this.props.clientItem.ReportComment, ['empty']),
                ReportObservationalScore: new Field('ReportObservationalScore', this.props.clientItem.ReportObservationalScore, ['empty']),
            }
        }
        if (!this.props.clientItem) {
            return {
                AssessmentID: new Field('AssessmentID', "", ['empty']),
                ReportGroupID: new Field('ReportGroupID', "", ['empty']),
                ClientID: new Field('ClientID', this.props.match.params.id, ['empty']),
            }
        }
    }

    getParams = () => {
        if (this.props.clientItem.ReportStatusID == 1) {
            return Object.assign({}, {
                ReportID: this.state.fields.ReportID.value,
                ReportStatusID: this.state.fields.ReportStatusID.value,
                ReportImagesQuantity: this.state.fields.ReportImagesQuantity.value,
                ReportTry: this.state.fields.ReportTry.value,
                SetID: typeof this.state.fields.SetID.value == "object" ? this.state.fields.SetID.value.value : this.state.fields.SetID.value,
            })
        }
        if (this.props.clientItem.ReportStatusID == 3) {
            return Object.assign({}, {
                ReportID: this.state.fields.ReportID.value,
                ReportStatusID: this.state.fields.ReportStatusID.value,
                ReportComment: this.state.fields.ReportComment.value,
                ReportObservationalScore: this.state.fields.ReportObservationalScore.value,
            })
        }
    }

    submit = (event) => {
        event && event.preventDefault();
        if (this.state.currQuestionType == 3) {
            this.handleInputChange("ReportImagesQuantity", "0")
            this.handleInputChange("ReportTry", "1")
        }
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                if (!!this.props.clientItem) {
                    this.props.dispatch(updateResource(Object.assign({
                            user: LocalStorage.get("user"),
                            params: this.getParams(),
                            query: this.props.query,
                            resource: Resources.Report,
                            piggyResource: Resources.Report,

                        }, !!this.props.fromHistory ? {
                            secondPiggyResource: Resources.ReportHistory,
                            piggyQuery: this.props.reportQuery,
                            secondPiggyQuery: this.props.query
                        } : {})
                    ));
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get("user"),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                            AssessmentID: this.state.fields.AssessmentID.value.value,
                            ReportGroupID: this.state.fields.ReportGroupID?.value?.value,
                            ReportGroup: this.state.fields.ReportGroupID?.value?.label,
                            DefaultMilestone: !!this.state.DefaultMilestone ? 1 : 0
                        }),
                        resource: Resources.Report,
                        query: this.props.query,
                        piggyResource: Resources.Report,
                    }));
                }
                if (!this.props.quizzStart) {
                    this.props.close()
                }
            }
        });
    }

    handleInputChange = (name, value) => {
        this.setState({canSubmit: true})
        if (name == "ReportImagesQuantity") {
            if (value == "" || value == "0") {
                this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)})
            }
            if (value <= this.state.currentNumber && value >= this.state.minimumNumberOfImages) {
                this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)})
            }
        } else {
            if (this.state.fields.SetID && value.value != this.state.fields.SetID.value && name == "SetID") {
                this.setState({fields: FieldsManager.updateField(this.state.fields, "ReportImagesQuantity", "")})
            }
            this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)}, () => {
                if (name == "SetID") {
                    this.state.currentSets.map(elem => {
                        if (elem.SetID == this.state.fields.SetID.value.value) {
                            this.setState({
                                currQuestionType: elem.QuestionTypeID,
                                currentNumber: elem.QuestionContent?.length,
                                minimumNumberOfImages: elem.CorrectAnswers + 1
                            }, () => {
                                // VIDEO je 1 QuestionTypeID
                                if (this.state.currQuestionType == 1) {
                                    this.handleInputChange("ReportImagesQuantity", 1)
                                    this.handleInputChange("ReportTry", 1)
                                }
                                if (this.state.currQuestionType == 2) {
                                    this.handleInputChange("ReportImagesQuantity", "")
                                    this.handleInputChange("ReportTry", "")
                                }
                                if (this.state.currQuestionType == 3) {
                                    this.handleInputChange("ReportImagesQuantity", "0")
                                    this.handleInputChange("ReportTry", "1")
                                }
                            })
                        }
                    })
                }
            });
        }
    };

    startQuizz = () => {
        if (this.state.currQuestionType == 3) {
            this.handleInputChange("ReportImagesQuantity", "0")
            this.handleInputChange("ReportTry", "1")
        }
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get("user"),
                    params: !!this.props.retake ? {ReportID: this.props.clientItem.ReportID} : this.getParams(),
                    query: this.props.query,
                    resource: !!this.props.retake ? Resources.ReportReStart : Resources.Report,
                    piggyResource: !!this.props.retake ? null : Resources.Report,
                }));
            }
        });
    }

    getSearchFields = () => {
        return {
            AssessmentOperantID: new Field("AssessmentOperantID", '', [''], false, 'select-search'),
            AssessmentLevel: new Field("AssessmentLevel", '', [''], false, 'select'),
        }
    }

    handleSearchSelectQueryChange = (name, value) => {
        this.setState({
            searchFields: FieldsManager.updateField(this.state.searchFields, name, value),
            fields: FieldsManager.updateField(this.state.fields, "AssessmentID", '')
        });
    }

    getAssessName = (it) => {
        return it.AssessmentName
            + '-'
            + it.Operant
            + '-'
            + it.AssessmentLevel
    }

    render() {
        const {translate, close} = this.props;

        const searchFieldsHtml = fieldsSearchToHtml(Object.values(Object.assign({}, this.state.searchFields)), translate, this.handleSearchSelectQueryChange, {
            AssessmentOperantID: {
                api: 'api/' + Resources.Operant,
                query: {},
                searchMap: (it) => ({
                    label: it.Operant,
                    value: it.OperantID
                })
            },
            AssessmentLevel: {
                1: 1,
                2: 2,
                3: 3
            },
            AssessmentMilestone: {
                1: 1,
                2: 2,
                3: 3,
                4: 4,
                5: 5,
                6: 6,
                7: 7,
                8: 8,
                9: 9,
                10: 10
            }
        });

        return (
            <Dialog
                visible={true}
                onClose={close}
                translate={translate}
                disableOverflow
                canSubmit={this.state.canSubmit}
                modalTitle={this.props.modalTitle}
            >
                {!!this.props.retake && (
                    <h2 className='text-center mb-3 font-bold'>Are you sure you want to retake this assessment?</h2>
                )}

                {!!this.props.quizzStart && (
                    <div className='start-assessment-text'>
                        <div><span>{translate("text.Objective")}: </span>{this.props.clientItem.ReportObjective}</div>
                        <div><span>{translate("text.ReportExample")}: </span>{this.props.clientItem.ReportExample}</div>
                        <div><span>{translate("text.ReportScoringOne")}: </span>{this.props.clientItem.ReportScoringOne}
                        </div>
                        <div>
                            <span>{translate("text.ReportScoringHalf")}: </span>{this.props.clientItem.ReportScoringHalf}
                        </div>
                        <div>
                            <span>{translate("text.ReportScoringZero")}: </span>{this.props.clientItem.ReportScoringZero}
                        </div>
                    </div>
                )}

                {!this.props.clientItem && !this.props.retake && (
                    <>
                        <div>
                            {searchFieldsHtml}

                            <div className={"grid-50-50"}>
                                <div className="modal-form-control-single">
                                    <div className='field-label'>{this.props.translate("field.Assessment")}*</div>
                                    <FieldDropdownSelect
                                        onChange={this.handleInputChange}
                                        {...this.state.fields.AssessmentID}
                                        defaultOptions={true}
                                        setKey={JSON.stringify(FieldsManager.getFieldKeyValues(this.state.searchFields))}
                                        loadOptions={
                                            (inputValue, callback) => {
                                                axios.get(
                                                    Env.getApiUrl("api/" + Resources.Assessments, Object.assign(
                                                        {query: inputValue, group: 1},
                                                        FieldsManager.getFieldKeyValues(this.state.searchFields)
                                                    )),
                                                    {
                                                        headers: {
                                                            'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                                        }
                                                    }
                                                )
                                                    .then((response) => {
                                                        const result = processResponse(response);

                                                        if (result && result.status === 0) {
                                                            const list = result.data.list.map((it) => {
                                                                if (it.AssessmentID == this.state.fields.AssessmentID.value) {
                                                                    this.handleInputChange("AssessmentID", {
                                                                        label: this.getAssessName(it),
                                                                        value: it.AssessmentID,
                                                                        AssessmentMilestone: it.AssessmentMilestone,
                                                                        item: it
                                                                    })
                                                                }
                                                                return {
                                                                    label: this.getAssessName(it),
                                                                    value: it.AssessmentID,
                                                                    AssessmentMilestone: it.AssessmentMilestone,
                                                                    item: it
                                                                };
                                                            });
                                                            callback(list);
                                                        }
                                                    })
                                            }
                                        }
                                        value={{
                                            label: this.state.fields.AssessmentID ? this.state.fields.AssessmentID.value.label : "",
                                            value: this.state.fields.AssessmentID ? this.state.fields.AssessmentID.value.value : ""
                                        }}
                                    />
                                </div>

                                <div className='modal-form-control-single'>
                                    <div className='field-label'>{this.props.translate("field.ReportGroupID")}*</div>
                                    <FieldCreatableSelect
                                        onChange={this.handleInputChange}
                                        {...this.state.fields.ReportGroupID}
                                        defaultOptions={true}
                                        loadOptions={
                                            (inputValue, callback) => {
                                                axios.get(
                                                    Env.getApiUrl("api/" + Resources.ReportGroups, {
                                                        query: inputValue,
                                                        ClientID: this.props.match.params.id,
                                                        archived: 0
                                                    }),
                                                    {
                                                        headers: {
                                                            'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                                        }
                                                    }
                                                )
                                                    .then((response) => {
                                                        const result = processResponse(response);
                                                        if (result && result.status === 0) {
                                                            const list = result.data.list.map((it) => {
                                                                if (it.ReportGroupID == this.state.fields.ReportGroupID.value) {
                                                                    this.handleInputChange("ReportGroupID", {
                                                                        label: it.ReportGroupName,
                                                                        value: it.ReportGroupID,
                                                                    })
                                                                }
                                                                return {
                                                                    label: it.ReportGroupName,
                                                                    value: it.ReportGroupID,
                                                                };
                                                            });
                                                            callback(list);
                                                        }
                                                    })
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        {/*this.state.fields.AssessmentID?.value?.AssessmentMilestone && (
                            <div className='modal-form-control-single'>
                                <div className="remember-me"
                                     onClick={() => this.setState({DefaultMilestone: !this.state.DefaultMilestone})}>
                                    <section>
                                        <div className="squaredFour">
                                            <input type="checkbox" checked={this.state.DefaultMilestone}/>
                                            <label htmlFor="DefaultMilestone"></label>
                                        </div>
                                    </section>
                                    <label className='cursor-pointer'
                                           htmlFor="DefaultMilestone">{translate("field.DefaultMilestone")} (Current: {this.state.fields.AssessmentID?.value?.AssessmentMilestone})</label>
                                </div>
                            </div>
                        )*/}
                    </>
                )}

                {!!this.props.clientItem && this.props.clientItem.ReportStatusID == 1 && !this.props.retake && (
                    <div>
                        <div className='modal-form-control-single'>
                            <div className='field-label'>{this.props.translate("field.Set")}</div>
                            <FieldDropdownSelect
                                onChange={this.handleInputChange}
                                {...this.state.fields.SetID}
                                defaultOptions={true}
                                loadOptions={
                                    (inputValue, callback) => {
                                        axios.get(
                                            Env.getApiUrl("api/" + Resources.SetsPublic, {query: inputValue}),
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                                }
                                            }
                                        )
                                            .then((response) => {
                                                const result = processResponse(response);
                                                if (result && result.status === 0) {
                                                    this.setState({currentSets: result.data.list})
                                                    const list = result.data.list.map((it) => {
                                                        if (it.SetID == this.state.fields.SetID.value) {
                                                            this.setState({
                                                                currentNumber: it.QuestionContent?.length,
                                                                currQuestionType: it.QuestionTypeID,
                                                                fields: FieldsManager.updateField(this.state.fields, "SetID", {
                                                                    label: it.SetName,
                                                                    value: it.SetID
                                                                })
                                                            })
                                                        }
                                                        return {
                                                            label: `${it.SetName}`,
                                                            value: it.SetID
                                                        };
                                                    });
                                                    callback(list);
                                                }
                                            })
                                    }
                                }
                                value={{
                                    label: this.state.fields.SetID ? this.state.fields.SetID.value.label : "",
                                    value: this.state.fields.SetID ? this.state.fields.SetID.value.value : ""
                                }}
                            />
                        </div>
                        {this.state.currQuestionType && this.state.currQuestionType == 2 && (
                            <div className='modal-form-control-single pt-4 pb-4'>
                                <div className='field-label'>{this.props.translate("field.ReportTry")}*</div>
                                <FieldText
                                    type={"number"}
                                    onChange={this.handleInputChange}
                                    {...this.state.fields.ReportTry}
                                    addClass={"modal-form-control-input"}
                                />
                            </div>
                        )}
                        {this.state.currQuestionType && this.state.currQuestionType == 2 && (
                            <div className='modal-form-control-single'>
                                <div className='field-label'>{this.props.translate("field.ReportImagesQuantity")}*</div>
                                <div className='flex justify-start items-center'>
                                    <FieldText
                                        type={"number"}
                                        onChange={this.handleInputChange}
                                        {...this.state.fields.ReportImagesQuantity}
                                        addClass={"modal-form-control-input-short"}
                                        placeholder={`${this.props.translate("text.min")} ${this.state.minimumNumberOfImages}`}
                                    />
                                    {!!this.state.currentNumber &&
                                        <span className='ml-10'>/{this.state.currentNumber}</span>}
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {!!this.props.clientItem && this.props.clientItem.ReportStatusID == 3 && !this.props.retake && (
                    <div>
                        <div className='modal-form-control'>
                            <div className='field-label'>{translate("field.ObservationalScore")}*</div>
                            <FieldText
                                onChange={this.handleInputChange}
                                {...this.state.fields.ReportObservationalScore}
                                addClass={"modal-form-control-input"}
                            />
                        </div>
                        <div className='modal-form-control pt-2'>
                            <div className='field-label'>{translate("field.Comment")}*</div>
                            <FieldTextarea
                                onChange={this.handleInputChange}
                                {...this.state.fields.ReportComment}
                                addClass={"form-control w-100"}
                            />
                        </div>
                    </div>
                )}
                <div className="flex justify-end mt-5">
                    <button type="button"
                            onClick={close}
                            className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer">{translate("btn.cancel")}
                    </button>
                    <button type="submit"
                            onClick={!!this.props.quizzStart ? this.startQuizz : this.submit}
                            className="btn btn-primary ml-3 focus:ring-offset-inverse hover:cursor-pointer">{translate(!!this.props.quizzStart ? "btn.start" : "btn.submit")}
                    </button>
                </div>
            </Dialog>
        );
    }
}

export default connect(state => state)(ReportDialog);
