import React, {Component} from "react";
import {connect} from "react-redux";
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {checkPerm, CREATE_PERM, fillFieldsFromData, getLookup, getProp, returnAge} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import LayoutDashboard from "../../components/layout-dashboard";
import PageHeading from "../../components/page-heading";
import FieldText from "../../components/field-text";
import SimpleTable from "../../components/simple-table";
import DocumentReportIcon from "@heroicons/react/outline/DocumentReportIcon";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import ModalConfirm from "../../components/modal/modal-confirm";
import ArchivedSwitch from "../../components/archived-switch";

class ClientsView extends Component {

    constructor(props) {
        super(props);
        this.excludedFromTable = ['ClientNotes'];
        this.state = {
            fields: this.getTableFields(null, this.excludedFromTable),
            limit: 10,
            paginationPage: 1,
            offset: 0,
            sort: 'ASC',
            sortBy: "",
            confirmModalOpen: false,
            clientFormDialog: false,
            selectedItem: {},
            deleteID: null,
            archived: 0,
            query: '',
            input: '',
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!this.props.resource.data && !this.props.resource.isLoading && prevProps.resource.isLoading) {
            const list = Object.assign({}, getProp(this.props.resource.data, "list", []));

            // Table fields
            this.setState({
                fields: this.getTableFields(list, this.excludedFromTable)
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.Clients
        }))
    };

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            query: this.state.input,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            archived: this.state.archived ? 1 : 0,
        }
    }

    /** UI Events
     ================================================================= */
    handleFilterChange = (name, val) => {
        this.setState({
            [name]: val,
            offset: 0,
            paginationPage: 1,
        }, this.fetchData)
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    toggleArchiveClient = (item = null) => {
        const selectedItem = this.state?.selectedItem || item;

        const id = selectedItem?.ClientID;
        this.setState({paginationPage: 1, offset: 0}, () => {
            if (selectedItem?.ArchivedDate === null) {
                this.props.dispatch(deleteResource({
                    user: LocalStorage.get('user'),
                    query: {
                        id: id,
                    },
                    piggyQuery: this.getQuery(),
                    resource: Resources.Clients,
                    piggyResource: Resources.Clients
                }));
            } else {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: {
                        ClientID: id,
                        ArchivedDate: 1
                    },
                    query: this.getQuery(),
                    resource: Resources.Clients,
                    piggyResource: Resources.Clients
                }));
            }
        })

        this.toggleConfirmDialog();
    }

    /** Helpers
     * ================================================================ */
    getPrimaryKey = () => {
        return "ClientID";
    }

    getResourceName = () => {
        return Resources.Clients;
    }

    toggleArchived = () => this.setState({archived: !this.state.archived}, () => this.fetchData())

    toggleConfirmDialog = (item = this.state.selectedItem) => {
        this.setState({confirmModalOpen: !this.state.confirmModalOpen, selectedItem: item});
    }

    getFields = (item = null, excludeFields = null) => {
        let fieldTemplates = {
            ClientID: new Field("ClientID", '', [''], false, 'text'),

            ClientFirstName: new Field("ClientFirstName", '', ['empty'], false, 'text'),
            ClientLastName: new Field("ClientLastName", '', ['empty'], false, 'text'),
            Client: new Field("Client", '', [''], false, 'custom', {
                render: (item) =>
                    <td
                        className="border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 h-12 cursor-pointer link"
                        onClick={() => this.toggleClientFormDialog(item)}
                    >
                        {item.ClientFirstName + " " + item.ClientLastName}
                    </td>
            }),
            ClientBirthday: new Field("ClientBirthday", '', ['empty'], false, 'date'),
            ClientAge: new Field("ClientAge", '', [''], false, 'custom', {
                render: (item) => (
                    <td className="border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 h-12">
                        {returnAge(item.ClientBirthday)}
                    </td>
                ),
                omitSort: true
            }),
            ClientGuardian: new Field("ClientGuardian", '', [''], false, 'custom', {
                render: (item) => (
                    <td className="border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 h-12">
                        {item.ClientGuardianFirstName + " " + item.ClientGuardianLastName}
                    </td>
                ),
                omitSort: true
            }),
            ClientGuardianFirstName: new Field("ClientGuardianFirstName", '', [''], false, 'text'),
            ClientGuardianLastName: new Field("ClientGuardianLastName", '', [''], false, 'text'),
            ClientGuardianTelephone: new Field("ClientGuardianTelephone", '', [''], false, 'text', {omitSort: true}),
            LocationID: new Field("LocationID", '', [''], false, 'select'),
            Clinician: new Field("Clinician", '', [''], false, 'select-search'),
            ClientLastSeen: new Field("ClientLastSeen", '', [''], false, 'datetime'),
            ClientNotes: new Field("ClientNotes", '', [''], false, 'textarea'),
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }
        fillFieldsFromData(fieldTemplates, item)
        fieldTemplates.Clinician.value = item ? {value: item.ContactID, label: item.Clinician} : {}
        return fieldTemplates;
    };

    getTableFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            ClientID: new Field("ClientID", '', [''], false, 'text'),

            ClientFirstName: new Field("Client", '', [''], false, 'custom', {
                render: (item) =>
                    <td
                        className="border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 h-12 cursor-pointer link"
                        onClick={() => this.toggleClientFormDialog(item)}
                    >
                        {item.ClientFirstName + " " + item.ClientLastName}
                    </td>
            }),
            ClientBirthday: new Field("ClientBirthday", '', [''], false, 'date'),
            ClientAge: new Field("ClientAge", '', [''], false, 'custom', {
                render: (item) => (
                    <td className="border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 h-12">
                        {returnAge(item.ClientBirthday)}
                    </td>
                ),
                omitSort: true
            }),
            ClientGuardian: new Field("ClientGuardian", '', [''], false, 'custom', {
                render: (item) => (
                    <td className="border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 h-12">
                        {item.ClientGuardianFirstName + " " + item.ClientGuardianLastName}
                    </td>
                ),
                omitSort: true
            }),
            ClientGuardianTelephone: new Field("ClientGuardianTelephone", '', [''], false, 'text', {omitSort: true}),
            Location: new Field("Location", '', [''], false, 'select'),
            Clinician: new Field("Clinician", '', [''], false, 'select-search'),
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    toggleClientFormDialog = (item = null) => {
        this.setState({
            clientFormDialog: !this.state.clientFormDialog,
            selectedItem: item
        })
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props;

        const data = getProp(resource.data, "list", []);
        const count = getProp(resource.data, "count", 0);
        const isLoading = getProp(this.props, "resource.isLoading", false);

        // Todo: this is not done yet
        const metadata = {
            Clinician: {
                api: 'api/' + Resources.Contacts,
                query: {},
                searchMap: (item) => ({
                    value: item.ContactID,
                    label: item.FirstName + " " + item.LastName
                })
            },
            LocationID: getLookup("Location", "LocationID", "Location")
        }

        return (
            <LayoutDashboard {...this.props} >
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12 relative">
                    <PageHeading
                        title="Clients"
                        titleClass="pb-1"
                    >
                        <ArchivedSwitch
                            classNameContainer="h-9 mr-2 mt-1"
                            translate={translate}
                            value={this.state.archived}
                            onChange={this.toggleArchived}
                        />

                        {
                            checkPerm(Resources.Clients, CREATE_PERM) && (
                                <button
                                    className="btn btn-primary ml-3"
                                    onClick={() => this.toggleClientFormDialog()}
                                >
                                    {translate("btn.add_new_client")}
                                </button>
                            )
                        }
                    </PageHeading>

                    <FieldText
                        placeholder={translate("text.search")}
                        className="w-72"
                        value={this.state.input}
                        name="input"
                        onChange={this.handleFilterChange}
                    />

                    <SimpleTable
                        data={data}
                        count={count}

                        fields={this.state.fields}
                        translate={this.props.translate}
                        isLoading={isLoading}

                        limit={this.state.limit}
                        offset={this.state.offset}
                        paginationPage={this.state.paginationPage}
                        onOffsetChange={this.handleUpdateOffset}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onDelete={(item) => this.toggleConfirmDialog(item)}
                        onRestore={(item) => this.toggleConfirmDialog(item)}
                        onRestoreLabel={translate("btn.activate")}
                        onEdit={(item) => this.toggleClientFormDialog(item)}
                        onDeleteLabel={translate("btn.deactivate")}
                        customActions={
                            [{
                                action: (item) => this.props.history.push(`report/${item.ClientID}`),
                                label: translate("btn.assess"),
                                icon: <DocumentReportIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-gray-400"/>
                            }]
                        }
                    />
                </div>

                <ModalSaveResource
                    title={(this.state.selectedItem ? "Edit" : "Add") + " Client"}
                    widthClass="max-w-lg"
                    visible={!!this.state.clientFormDialog}
                    onClose={this.toggleClientFormDialog}
                    blurBackdrop={true}
                    fields={this.getFields(this.state.selectedItem, ["ClientID", "Client", "ClientLastSeen"])}
                    onSubmit={(params) => {
                        if (params) {
                            params.ContactID = params.Clinician
                            if (!this.state.selectedItem) {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                            } else {
                                params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                            }
                            this.toggleClientFormDialog();
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalConfirm
                    visible={this.state.confirmModalOpen}
                    title={translate(this.state.selectedItem?.ArchivedDate === null ? "text.delete_client" : "text.restore_client")}
                    text={`${translate(this.state.selectedItem?.ArchivedDate === null ? "text.are_you_sure_you_want_to_deactivate" : "text.are_you_sure_you_want_to_activate")} ${this.state.selectedItem?.ClientFirstName} ${this.state.selectedItem?.ClientLastName}?`}
                    onClose={this.toggleConfirmDialog}
                    onConfirm={() => this.toggleArchiveClient()}
                />
            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(ClientsView);
