import React, {Component} from "react";
import {connect} from "react-redux";
import LoaderSmall from "../../components/loader-small";
import {
    createResource,
    deleteResource,
    getResource,
    resetResourceMessage,
    updateResource
} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {checkPerm, CREATE_PERM, fillFieldsFromData, getProp,} from "../../util/util";
import PageHeading, {GoBackIcon} from "../../components/page-heading";
import LayoutDashboard from "../../components/layout-dashboard";
import SimpleTable from "../../components/simple-table";
import {Field} from "../../data/services/fields";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import ModalConfirm from "../../components/modal/modal-confirm";
import ArchivedSwitch from "../../components/archived-switch";

class ReportGroupsView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            limit: 10,
            offset: 0,
            sort: "ASC",
            sortBy: "",
            createGroupReportDialog: false,
        };
    }

    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.resource !== prevProps.resource && this.props.resource?.errorMessage === "REPORT_GROUP_NAME_ALREADY_EXISTS") {
            this.setState({groupExist: true})
        }
        if (this.props.resource !== prevProps.resource && this.props.resource?.create?.id) {
            this.closeCreateGroupReportDialog()
        }
        if (this.props.resource !== prevProps.resource && this.props.resource?.update) {
            this.closeCreateGroupReportDialog()
        }
        if ((prevState.sort !== this.state.sort) || (prevState.archived !== this.state.archived) || (prevState.limit !== this.state.limit) || (prevState.sortBy !== this.state.sortBy && this.state.sortBy !== "")) {
            this.fetchData();
        }
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            resource: Resources.ReportGroups,
            query: this.getQuery()
        }))
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            archived: this.state.archived ? 1 : 0,
            ClientID: this.props.match.params.id
        }
    }

    getFields = (item = {}) => {
        let fieldTemplates = {
            ReportGroupName: new Field("ReportGroupName", '', ['empty'])
        }
        return fillFieldsFromData(fieldTemplates, item);
    }

    returnSortIcons = (value) => {
        if (this.state.sortBy === value) {
            if ((this.state.sort === "DESC")) {
                return (
                    <div className="up-n-down ml-10 color-white">
                        <i className="fas fa-chevron-up opacity-0-4 color-white"/>
                        <i className="fas fa-chevron-down color-white"/>
                    </div>
                )
            } else {
                return (
                    <div className="up-n-down ml-10 color-white">
                        <i className="fas fa-chevron-up color-white"/>
                        <i className="fas fa-chevron-down opacity-0-4 color-white"/>
                    </div>
                )
            }
        } else {
            return (
                <div className="up-n-down ml-10 color-white">
                    <i className="fas fa-chevron-up color-white"/>
                    <i className="fas fa-chevron-down color-white"/>
                </div>
            )
        }
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, this.fetchData);
    }

    deleteGroup = () => {
        if (!this.state.selectedItem.ArchivedDate) {
            this.setState({paginationPage: 1, offset: 0}, () => {
                this.props.dispatch(deleteResource({
                    user: LocalStorage.get("user"),
                    query: Object.assign({
                        id: this.state.selectedItem.ReportGroupID,
                    }),
                    resource: Resources.ReportGroups,
                    piggyQuery: this.getQuery(),
                    piggyResource: Resources.ReportGroups
                }))
                this.hideConfirmDialog()
            })
        } else {
            this.props.dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: {
                    ReportGroupID: this.state.selectedItem.ReportGroupID,
                    ArchivedDate: 1
                },
                query: this.getQuery(),
                resource: Resources.ReportGroups,
                piggyResource: Resources.ReportGroups
            }));
            this.hideConfirmDialog()
        }
    }

    createGroupReportHandler = (fields) => {
        if (this.state.selectedItem) {
            this.props.dispatch(updateResource({
                user: LocalStorage.get("user"),
                resource: Resources.ReportGroups,
                params: Object.assign({
                    ClientID: this.props.match.params.id,
                    ReportGroupName: fields.ReportGroupName.value,
                    ReportGroupID: this.state.selectedItem.ReportGroupID
                }),
                query: this.getQuery(),
                piggyResource: Resources.ReportGroups
            }))
        } else {
            this.props.dispatch(createResource({
                user: LocalStorage.get("user"),
                resource: Resources.ReportGroups,
                params: Object.assign({
                    ClientID: this.props.match.params.id,
                    ReportGroupName: fields.ReportGroupName.value
                }),
                query: this.getQuery(),
                piggyResource: Resources.ReportGroups
            }))
        }
    }

    toggleClientFormDialog = (item = null) => {
        this.setState({selectedItem: item, createGroupReportDialog: !this.state.createGroupReportDialog})
    }

    toggleConfirmDialog = (item = null) => {
        this.setState({confirmDialog: !this.state.confirmDialog, selectedItem: item});
    }

    toggleArchived = () => this.setState({archived: !this.state.archived})

    showConfirmDialog = item => this.setState({confirmDialog: true, selectedItem: item});
    hideConfirmDialog = () => this.setState({confirmDialog: false, selectedItem: null});

    closeCreateGroupReportDialog = () => {
        if (this.props.resource.errorMessage) this.props.dispatch(resetResourceMessage())
        this.setState({createGroupReportDialog: false, selectedItem: null, groupExist: false})
    }

    render() {
        const {translate, resource} = this.props;
        const data = Array.isArray(getProp(resource.data, "list", [])) && getProp(resource.data, "list", [])
        const count = getProp(resource.data, "count", 0)
        return (
            <LayoutDashboard {...this.props} >
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12 relative">
                    <PageHeading
                        title={<h1 className='color-dark-blue'>
                            <GoBackIcon
                                onClick={() => this.props.history.push("/report/" + this.props.match.params.id)}
                            />
                            {translate("page.title.Groups")}
                        </h1>}
                        titleClass="pb-1"
                    >
                        <ArchivedSwitch
                            classNameContainer="h-9 mr-2 mt-1"
                            translate={translate}
                            value={this.state.archived}
                            onChange={this.toggleArchived}
                        />

                        {
                            checkPerm(Resources.ReportGroups, CREATE_PERM) && (
                                <button
                                    className="btn btn-outline ml-3"
                                    onClick={() => this.setState({createGroupReportDialog: true})}
                                >
                                    {translate("btn.create")}
                                </button>
                            )
                        }
                    </PageHeading>

                    <SimpleTable
                        data={data}
                        count={count}

                        fields={this.state.fields}
                        translate={this.props.translate}
                        isLoading={resource.isLoading}

                        limit={this.state.limit}
                        offset={this.state.offset}
                        paginationPage={this.state.paginationPage}
                        onOffsetChange={this.handleUpdateOffset}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onDelete={(item) => this.toggleConfirmDialog(item)}
                        onRestore={(item) => this.toggleConfirmDialog(item)}
                        onEdit={(item) => this.toggleClientFormDialog(item)}
                    />


                    {this.props.resource.isLoading && (
                        <LoaderSmall/>
                    )}
                    {(data.length === 0) && !resource.isLoading && (
                        <div className="margin-auto text-center mt-10">
                            {translate("text.no_records")}
                        </div>
                    )}
                    {!!this.state.confirmDialog && (
                        <ModalConfirm
                            visible={true}
                            title={translate(this.state.selectedItem.ArchivedDate === null ? "text.delete_group" : "text.restore_group")}
                            text={`${translate(this.state.selectedItem.ArchivedDate === null ? "text.are_you_sure_you_want_to_delete" : "text.are_you_sure_you_want_to_restore")} ${this.state.selectedItem.ReportGroupName}?`}
                            onClose={this.toggleConfirmDialog}
                            onConfirm={() => this.deleteGroup()}
                        />
                    )}

                    <ModalSaveResource
                        title={(this.state.selectedItem ? "Edit" : "Save") + " Report Date"}
                        widthClass="max-w-lg"
                        visible={!!this.state.createGroupReportDialog}
                        onClose={this.closeCreateGroupReportDialog}
                        blurBackdrop={true}
                        fields={this.getFields(this.state.selectedItem)}
                        errorMessage={this.props.resource.errorMessage}
                        onSubmit={(params) => {
                            if (params) {
                                if (this.state.selectedItem) {
                                    this.props.dispatch(updateResource({
                                        user: LocalStorage.get("user"),
                                        resource: Resources.ReportGroups,
                                        params: Object.assign({
                                            ClientID: this.props.match.params.id,
                                            ReportGroupName: params.ReportGroupName,
                                            ReportGroupID: this.state.selectedItem.ReportGroupID
                                        }),
                                        query: this.getQuery(),
                                        piggyResource: Resources.ReportGroups
                                    }))
                                } else {
                                    this.props.dispatch(createResource({
                                        user: LocalStorage.get("user"),
                                        resource: Resources.ReportGroups,
                                        params: Object.assign({
                                            ClientID: this.props.match.params.id,
                                            ReportGroupName: params.ReportGroupName
                                        }),
                                        query: this.getQuery(),
                                        piggyResource: Resources.ReportGroups
                                    }))
                                }
                            }
                        }}
                        translate={this.props.translate}
                    />
                </div>
            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(ReportGroupsView);
