import React, {Component} from 'react';
import Select, {components, createFilter} from 'react-select';
import {getCustomStyles} from "../../styles/theme-styles";

const CustomOption = props => {
    const {data, className} = props;
    return <components.Option {...props} className={className + (data.className ? " " + data.className : "")}/>;
};

export default class FieldSelectSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const pairs = this.props.values ? this.props.values : {};

        let values = Object.keys(pairs);

        if (!this.props.omitSort) {
            values = values.sort((a, b) => {
                return (pairs[a] == pairs[b]) ? 0 : ((pairs[a] > pairs[b]) ? 1 : -1);
            });
        }

        values = values.map((key, i) => ({value: key, label: pairs[key]}));

        const filterConfig = {
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: 'any',
        };
        return (
            <React.Fragment>
                <Select
                    placeholder={this.props.placeholder ? this.props.placeholder : "Select..."}
                    isMulti={this.props.isMulti}
                    onChange={(selectedOption) => {
                        this.props.onChange(this.props.name, selectedOption ? (!this.props.options ? selectedOption.value : selectedOption) : "");
                    }}
                    styles={getCustomStyles()}
                    className={"select-search " + (this.props.className)}
                    value={!this.props.options ? (this.props.value ? {
                        value: this.props.value,
                        label: pairs[this.props.value]
                    } : null) : this.props.value}
                    isClearable={!!this.props.all}
                    isSearchable={true}
                    isDisabled={this.props.disabled}
                    name={this.props.name}
                    options={this.props.options ? this.props.options : values}
                    filterOption={createFilter(filterConfig)}
                    components={{Option: CustomOption}}
                />
                {this.props.errorMessage && (
                    <p className="mt-2 text-sm text-red-600">
                        {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Please fill out this field."}
                    </p>
                )}
            </React.Fragment>
        );
    }
}