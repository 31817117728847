import React, {Component} from "react";
import {connect} from "react-redux";
import Dialog from '../../../components/dialog'
import {Field, FieldsManager} from "../../../data/services/fields";
import Resources from "../../../data/services/resources";
import FieldDropdownSelect from "../../../components/field-dropdown-select";
import axios from "axios";
import Env from "../../../util/env";
import LocalStorage from "../../../util/localStorage";
import {processResponse} from "../../../data/services/api-util";
import FieldText from "../../../components/field-text";

class CopyGroupReportDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            activeTab: "select"
        };
    }

    handleInputChange = (name, value) => {
        this.setState({canSubmit: true, fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submit = () => {
        this.setState({no_match: false, fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.submit(this.state.fields)
            }
        });
    }

    getReportIDs = arr => {
        return arr.map(item => item.ReportID)
    }

    getFields = () => {
        return {
            ReportGroupID: new Field("ReportGroupID", "-1", ['empty']),
            ReportGroupName: new Field("ReportGroupName", '', []),
            ReportCopyIDs: new Field("ReportCopyIDs", this.getReportIDs(this.props.ReportCopyIDs), ['']),
        }
    }

    setActiveTab = activeTab => {
        let fields = this.state.fields
        if (activeTab === "create") {
            fields.ReportGroupName.validate = ['empty']
            fields.ReportGroupID.validate = []
            fields.ReportGroupID.value = "-1"
        } else {
            fields.ReportGroupName.validate = []
            fields.ReportGroupID.validate = ['empty']
        }
        this.setState({activeTab: activeTab, fields: fields})
    }

    render() {
        const {translate, close} = this.props;
        const activeTabClass = "border-transparent text-secondary-700 hover:text-primary-600 hover:border-primary-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
        const inactiveTabClass = "border-primary-500 text-primary-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";

        return (
            <Dialog
                visible={true}
                onClose={close}
                disableOverflow
                modalTitle={"Copy Group"}
                translate={translate}
            >


                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    <button
                        onClick={() => this.setActiveTab("select")}
                        className={this.state.activeTab === "select" ? inactiveTabClass : activeTabClass}>{translate("text.Select")}
                    </button>
                    <button
                        onClick={() => this.setActiveTab("create")}
                        className={this.state.activeTab === "create" ? inactiveTabClass : activeTabClass}>{translate("text.Create")}
                    </button>
                </nav>

                {this.state.activeTab === "select" && (
                    <div className='modal-form-control-single'>
                        <div className='field-label'>{this.props.translate("field.ReportGroupID")}</div>
                        <FieldDropdownSelect
                            onChange={this.handleInputChange}
                            {...this.state.fields.ReportGroupID}
                            defaultOptions={true}
                            loadOptions={
                                (inputValue, callback) => {
                                    axios.get(
                                        Env.getApiUrl("api/" + Resources.ReportGroups, {
                                            query: inputValue,
                                            ClientID: this.props.ClientID,
                                            archived: 0
                                        }),
                                        {
                                            headers: {
                                                'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                            }
                                        }
                                    )
                                        .then((response) => {
                                            const result = processResponse(response);
                                            if (result && result.status === 0) {
                                                const list = result.data.list.map((it) => {
                                                    return {
                                                        label: it.ReportGroupName,
                                                        value: it.ReportGroupID,
                                                    };
                                                });
                                                callback(list);
                                            }
                                        })
                                }
                            }
                        />
                    </div>
                )}
                {this.state.activeTab === "create" && (
                    <>
                        {this.props.groupExist && (
                            <div className='email-error mb-25'>
                                {translate("text.ReportGroupNameAlreadyExists")}
                            </div>
                        )}
                        <div className='modal-form-control'>
                            <div className='field-label'>{translate("field.ReportGroupName")} *</div>
                            <FieldText
                                onChange={this.handleInputChange}
                                {...this.state.fields.ReportGroupName}
                                addClass={"modal-form-control-input"}
                            />
                        </div>
                    </>
                )}

                <div className="pt-5">
                    <div className="flex justify-end">
                        <button type="button"
                                className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer"
                                onClick={close}
                        >{translate("btn.close")}
                        </button>
                        <button type="submit"
                                onClick={this.submit}
                                className="btn btn-primary ml-3 focus:ring-offset-inverse hover:cursor-pointer"
                        >{translate("btn.copy")}
                        </button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default connect(state => state)(CopyGroupReportDialog);
