import React, {Component} from "react";
import {fieldsToHtml, scrollErrorIntoView} from "../../util/util";
import {FieldsManager} from "../../data/services/fields";
import DialogDefault from "../dialog";

export default class ModalSaveResource extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.props.fields
        }

        this.cancelButtonRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.visible !== this.props.visible) && !!this.props.visible) {
            this.setState({
                fields: this.props.fields
            })
        }
    }

    handleInputChange = (name, value) => {
        const fields = this.props.handleInputChange
            ? this.props.handleInputChange(this.state.fields, name, value)
            : FieldsManager.updateField(this.state.fields, name, value);

        if (this.props.onStateDirty) this.props.onStateDirty(true)
        this.setState({fields: fields});
    };

    getValidFields = () => {
        let validatedFields = FieldsManager.validateFields(this.state.fields);
        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            return FieldsManager.getFieldKeyValues(validatedFields);
        } else {
            this.setState({fields: validatedFields});

            scrollErrorIntoView(validatedFields);
        }
    }

    render() {
        const {onSubmit, onClose, visible, translate, title, metadata, onCustomActions} = this.props;

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, metadata);

        return (
            <DialogDefault
                title={title}
                widthClass={this.props.widthClass}
                visible={visible}
                blurBackdrop={this.props.blurBackdrop}
                onClose={onClose}
                translate={translate}
                disableOverflow={this.props.disableOverflow}
            >
                {this.props.errorMessage && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                         role="alert">
                        <strong className="font-bold">{translate(this.props.errorMessage)}</strong>
                    </div>
                )}
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                    {fieldsHtml}
                </div>

                <div className="pt-5">
                    <div className="flex justify-end">

                        {!!onCustomActions && onCustomActions()}

                        <button type="button"
                                className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer"
                                onClick={(e) => onClose()}
                        >
                            {this.props.closeButtonName ? this.props.closeButtonName : translate("btn.close")}
                        </button>

                        <button
                            type="submit"
                            className="btn btn-primary ml-3 focus:ring-offset-inverse hover:cursor-pointer"
                            onClick={() => onSubmit(this.getValidFields())}
                        >
                            {this.props.submitButtonName ? this.props.submitButtonName : translate("btn.save")}
                        </button>
                    </div>
                </div>

            </DialogDefault>

        );
    }
}
