export default class Resources {

    static User = "user";
    static UserChangePassword = "user/change-password";

    static UserDevices = "user/logged-devices";
    static UserReports = "users/report";

    static Resource = "resource"
    static Lookup = "lookup"
    static Operant = "operant";
    static Clients = "clients"
    static Users = "users"
    static Contacts = "contacts"
    static AssessmentsHistory = "assessments/history"
    static Assessments = "assessments"
    static Sets = "sets"
    static SetsPublic = "sets/public"
    static SetsImages = "sets/image/preview"
    static SetsImage = "sets/images"
    static Report = "report"
    static ReportStart = "report/start"
    static ReportReStart = "report/restart"
    static ReportHistory = "report/history"
    static ReportNext = "report/next"
    static ReportFinish = "report/finish"
    static ReportRandomize = "report/randomize"
    static ReportDocuments = "report/report"
    static ReportDocumentsPDF = "report/report/pdf"
    static ReportGroups = "report/group"
    static ReportGroupsCopy = "report/copy"
    static Company = "company";
}
