import React, {Component} from "react";
import {connect} from "react-redux";
import {getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {getProp} from "../../util/util";
import Env from "../../util/env";
import Button from "../../components/button";
import QuizzFinishDialog from "../../components/quizzFinishDialog";
import LoaderSmall from "../../components/loader-small";
import LayoutDashboard from "../../components/layout-dashboard";

class QuizzView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clientItem: null,
            selectedImages: []
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get("user"),
            params: {
                ReportID: this.props.match.params.id
            },
            query: {
                ReportID: this.props.match.params.id
            },
            resource: Resources.ReportStart,
            piggyResource: Resources.ReportStart,
        }))
    };

    submitVideoFinishHandler = (item) => this.setState({clientItem: item, quizzFinishHandler: true})
    closeSubmitVideoFinishHandler = () => this.setState({clientItem: null, quizzFinishHandler: false})

    nextQuizzHandler = (items, index) => {
        let arr = items
        arr[index].selected = true
        this.props.dispatch(updateResource({
            user: LocalStorage.get("user"),
            params: {
                Answers: arr,
                ReportID: this.props.match.params.id
            },
            query: {
                ReportID: this.props.match.params.id
            },
            resource: Resources.ReportNext,
            piggyResource: Resources.ReportRandomize,
        }))
        this.resetState()
    }

    randomizeQuizzHandler = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                ReportID: this.props.match.params.id
            },
            resource: Resources.ReportRandomize,
        }))
        this.resetState()
    }

    resetState = () => {
        const newState = Object.keys(this.state).reduce((memo, elem) => {
            switch (elem) {
                case "clientItem":
                case "selectedImages":
                    memo[elem] = null;
                    break;
                default:
                    memo[elem] = false;
            }
            return memo;
        }, {});

        this.setState(newState);
    }

    selectImageAnswerHandler = elem => {
        let array = this.state.selectedImages
        if (!this.state[elem.source]) {
            array.push(elem)
            this.setState({[elem.source]: true})
        } else if (!!this.state[elem.source]) {
            for (let i = 0; i < array.length; i++) {
                if (array[i].source == elem.source) {
                    array.splice(i, 1)
                    this.setState({[elem.source]: false})
                }
            }
        }
        this.setState({selectedImages: array})
    }

    render() {
        const {translate, resource} = this.props;
        const dataItems = getProp(resource.data, "quiz", {})
        const ClientID = getProp(resource.data, "quiz.ClientID", 0)
        let video = dataItems !== null && Object.keys(dataItems).length > 0 && dataItems.QuestionContent?.map(elem => {
            const imageUrl = !!elem.source && Env.getApiUrl("api/" + Resources.SetsImages, {
                access_token: LocalStorage.get('user').access_token,
                PathName: elem.source.split("/")[elem.source.split("/").length - 1]
            });
            return (
                <React.Fragment>
                    <video className='drag-n-drop-video-preview' controls>
                        <source src={imageUrl} type="video/mp4"/>
                        <source src={imageUrl} type="video/ogg"/>
                    </video>
                </React.Fragment>
            )
        })

        let images = dataItems !== null && Object.keys(dataItems).length > 0 && dataItems.QuestionContent?.map((elem, i) => {
            const imageUrl = !!elem.source && Env.getApiUrl("api/" + Resources.SetsImages, {
                access_token: LocalStorage.get('user').access_token,
                PathName: elem.source.split("/")[elem.source.split("/").length - 1]
            });
            return (
                <div>
                    <div
                        onClick={() => this.nextQuizzHandler(Object.keys(dataItems).length > 0 && dataItems.QuestionContent, i)}
                        className={`quizz-images-picker-image position-relative cursor-pointer `}
                        style={{background: `url(${imageUrl})`}}>
                    </div>
                    <div className='quizz-images-picker-answer'>
                        {elem.AnswerLabel}
                    </div>
                </div>
            )
        });

        return (
            <LayoutDashboard {...this.props}>
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12 relative">
                    {!this.props.resource.isLoading && dataItems.QuestionTypeID == 1 &&
                        <h1 className='color-dark-blue text-center'>{dataItems.SetLabel}</h1>}
                    {!this.props.resource.isLoading && dataItems.QuestionTypeID == 2 && Object.keys(dataItems).length > 0 && ((+dataItems.ReportCurrentTries + 1) <= +dataItems.ReportTry) &&
                        <div className="grid-3">
                            <div></div>
                            <div>
                                <h1 className='color-dark-blue text-center'>{dataItems.SetLabel}</h1>
                            </div>
                            <div
                                className='flex-center-end'>{translate("text.num_of_tries")}: {+dataItems.ReportCurrentTries + 1}/{dataItems.ReportTry}</div>
                        </div>
                    }
                    {!this.props.resource.isLoading && dataItems.QuestionTypeID == 1 && ((+dataItems.ReportCurrentTries + 1) <= +dataItems.ReportTry) && (
                        <div className='quizz-video-content'>
                            {video}
                            <div className="quizz-video-content-submit">
                                <div className="modal-buttons ">
                                    <div className="block-margin-bottom-10">
                                        <Button className="buttons"
                                                onClick={() => this.submitVideoFinishHandler(dataItems)}
                                                text={translate("btn.finish")}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {!this.props.resource.isLoading && dataItems.QuestionTypeID == 3 && ((+dataItems.ReportCurrentTries + 1) <= +dataItems.ReportTry) && (
                        <div className='quizz-video-content'>
                            <div>
                                <h1 className='color-dark-blue text-center'>{dataItems.SetLabel}</h1>
                            </div>
                            <div className="quizz-video-content-submit">
                                <div className="modal-buttons ">
                                    <div className="block-margin-bottom-10">
                                        <Button className="buttons"
                                                onClick={() => this.submitVideoFinishHandler(dataItems)}
                                                text={translate("btn.finish")}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {this.props.resource.isLoading && (
                        <LoaderSmall/>
                    )}
                    {((+dataItems.ReportCurrentTries + 1) > +dataItems.ReportTry) && (
                        <QuizzFinishDialog
                            modalTitle={translate("btn.finish")}
                            clientItem={dataItems}
                            translate={translate}
                            close={this.closeSubmitVideoFinishHandler}
                        />
                    )}
                    {!this.props.resource.isLoading && dataItems.QuestionTypeID == 2 && ((+dataItems.ReportCurrentTries + 1) <= +dataItems.ReportTry) && (
                        <div className='quizz-images-content'>
                            <div className='quizz-images-picker'>
                                {images}
                            </div>
                            <div className="pt-5">
                                <div className="flex justify-end">
                                    <button type="button"
                                            onClick={() => this.props.history.push(`/report/${ClientID}`)}
                                            className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer">{translate("btn.close")}
                                    </button>
                                    <button type="submit"
                                            onClick={() => this.randomizeQuizzHandler()}
                                            className="btn btn-primary ml-3 focus:ring-offset-inverse hover:cursor-pointer">{translate("btn.randomize")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {(!!this.state.quizzFinishHandler || dataItems.QuestionTypeID == 3) && (
                    <QuizzFinishDialog
                        modalTitle={translate("btn.finish")}
                        clientItem={dataItems}
                        translate={translate}
                        close={this.closeSubmitVideoFinishHandler}
                    />
                )}
            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(QuizzView);
