import {logout} from "../data/actions/user";
import {pushNotification} from "../data/actions/ui";

const eventsMiddleware = store => next => action => {

    if (action.data === "invalid_token") {
        store.dispatch(logout());
        return store.getState();
    }

    if (action.type.indexOf("ERROR_") === 0) {
        const notificationData = {
            data: {
                notificationMessage: action.data,
                error: true,
            }
        }
        store.dispatch(pushNotification(notificationData))
    }

    return next(action);
};

export default eventsMiddleware
