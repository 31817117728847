import React, {Component} from "react";
import {connect} from "react-redux";
import PublicLayout from "../../components/layout";
import LoaderSmall from "../../components/loader-small";
import {getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {getProp} from "../../util/util";
import Logout from "../../components/logout/index";

class HomeView extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {},
            resource: Resources.Resource
        }))
    };

    render() {
        const {translate, resource, history, dispatch} = this.props;

        const data = getProp(resource, "data", []).map((item, i) => {
            return (<li key={i}>{item.value}</li>)
        });

        return (
            <PublicLayout {...this.props} pageTitle={translate("page.title.home")}>
                <h1>{translate("text.dashboard")}</h1>

                <div className="link-wrapper">
                    <Logout history={history} translate={translate} dispatch={dispatch}/>
                </div>

                {this.props.resource.isLoading && (
                    <LoaderSmall/>
                )}

                <ul>
                    {data}

                    {!this.props.resource.isLoading && (data.length === 0) && (
                        <li><strong>{translate("app.no_data")}</strong></li>
                    )}
                </ul>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(HomeView);