import React, {Component} from "react";
import Async from "react-select/async-creatable";
import {getCustomStyles} from "../../styles/theme-styles"

export default class FieldCreatableSelect extends Component {

    render() {
        const pairs = this.props.values ? this.props.values : {};

        const formatGroupLabel = data => (
            <div>
                <span>{data.label}</span>
                <span>{data.options.length}</span>
            </div>
        );

        return (
            <React.Fragment>
                <Async
                    ref={this.props.innerRef}
                    styles={getCustomStyles()}
                    className={"select-search " + (this.props.className)}
                    isDisabled={!!this.props.disabled}
                    onChange={(selectedOption) => {
                        this.props.onChange(this.props.name, selectedOption);
                    }}
                    name={this.props.name}
                    value={!this.props.loadOptions ? (this.props.value ? {
                        value: this.props.value,
                        label: pairs[this.props.value]
                    } : null) : this.props.value}
                    options={this.props.options}
                    onCreateOption={this.props.onCreateOption ? (value) => this.props.onCreateOption(this.props.name, value, this.props.onChange) : (value) => {
                        this.props.onChange(this.props.name, {
                            value: -1,
                            label: value
                        })
                    }}
                    loadOptions={this.props.loadOptions}
                    defaultOptions={!!this.props.defaultOptions}
                    isClearable={!!this.props.isClearable}
                    isMulti={this.props.multi}
                    Placeholder={!!this.props.placeholder ? this.props.placeholder : 'Select...'}
                    placeholder={!!this.props.placeholder ? this.props.placeholder : 'Select...'}
                    formatGroupLabel={formatGroupLabel}
                />

                {this.props.errorMessage && (
                    <p className="mt-2 text-sm text-red-600">
                        {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Please fill out this field."}
                    </p>
                )}
            </React.Fragment>
        )
    }
}
