import React, {Component} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {toBackDateTime} from "../../util/util";
import moment from "moment";

export default class FieldDate extends Component {

    render() {
        const addClass = "form-control " + (this.props.className ? " " + this.props.className : "");

        return (
            <React.Fragment>
                <span className={this.props.isClearable ? "relative" : ""}>
                    <DatePicker
                        selected={moment(this.props.value).isValid() ? moment(this.props.value).toDate() : null}
                        onChange={(date) => {
                            this.props.onChange(this.props.name, !!date ? toBackDateTime(date) : "");
                        }}
                        minDate={this.props.minDate ? moment(this.props.minDate).toDate() : null}
                        maxDate={this.props.maxDate ? moment(this.props.maxDate).toDate() : null}
                        disabled={this.props.disabled}
                        showTimeSelect={this.props.showTimeSelect}
                        timeFormat={this.props.timeFormat ?? "h:mm aa"}
                        showTimeSelectOnly={this.props.showTimeSelectOnly}
                        dateFormat={this.props.dateFormat ?? "MM/dd/yyyy h:mm aa"}
                        timeIntervals={15}
                        timeCaption="time"
                        showYearDropdown={true}
                        popperPlacement={this.props.placement ? this.props.placement : "bottom-start"}
                        className={"datepickerIcon " + addClass + (this.props.errorMessage ? " is-invalid" : "")}
                    />
                    {this.props.isClearable && (
                        <svg
                            onClick={() => this.props.onChange(this.props.name, "")}
                            height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"
                            className="absolute right-3 top-0.5 cursor-pointer text-gray-400 hover:text-gray-700"
                        >
                            <path style={{fill: "currentColor"}}
                                  d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                        </svg>
                    )}
                </span>

                {this.props.errorMessage && (
                    <p className="mt-2 text-sm text-red-600">
                        {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Please fill out this field."}
                    </p>
                )}
            </React.Fragment>
        )
    }
}

