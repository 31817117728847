import React, {Component} from "react";
import {connect} from "react-redux";
import LoaderSmall from "../../components/loader-small";
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {
    checkPerm,
    classNames,
    CREATE_PERM,
    DELETE_PERM,
    getLookup,
    getProp,
    reportStatus,
    toFrontDateTimeFromUTC,
    UPDATE_PERM
} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import ConfirmDialog from "../../components/confirmDialog";
import ReportDialog from "../../components/reportDialog";
import ReportHistoryDialog from "../../components/reportDialog/reportHistoryDialog";
import CopyGroupReportDialog from "../report-groups/copy-report-group";
import LayoutDashboard from "../../components/layout-dashboard";
import PageHeading, {GoBackIcon} from "../../components/page-heading";
import ModalConfirm from "../../components/modal/modal-confirm";
import FieldSelect from "../../components/field-select";
import ArchivedSwitch from "../../components/archived-switch";

class ReportView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headers: this.getHeaders(),
            limit: 500,
            paginationPage: 1,
            offset: 0,
            sort: 'ASC',
            sortBy: "ReportGroupID",
            confirmDialog: false,
            addNewClient: false,
            clientItem: {},
            deleteID: null,
            input: "",
            quizzStart: false,
            copyGroupReportDialog: false,
            ReportCopyIDs: [],
            groupExist: false,
            fields: {
                ReportGroupName: new Field('ReportGroupName', 0, ['']),
                SetLevel: new Field('SetLevel', null, ['']),
            }
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevState.sort !== this.state.sort) || (prevState.archived !== this.state.archived) || (prevState.limit !== this.state.limit) || (prevState.sortBy !== this.state.sortBy && this.state.sortBy !== "")) {
            this.fetchData();
        }
        if (this.props.resource !== prevProps.resource && this.props.resource?.errorMessage === "REPORT_GROUP_NAME_ALREADY_EXISTS") {
            this.setState({groupExist: true})
        }
        if (this.props.resource !== prevProps.resource && this.props.resource?.create?.id) {
            this.setState({ReportCopyIDs: [], copyGroupReportDialog: false, groupExist: false})
        }
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.Report
        }))
    };

    getQuery = () => {
        return {
            limit: this.state.limit,
            query: this.state.input,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            archived: this.state.archived ? 1 : 0,
            ClientID: this.props.match.params.id
        }
    }

    updateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        })
    };

    updateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, this.fetchData);
    }

    handleFilterChange = (name, val) => {
        this.setState({
            [name]: val,
            offset: 0,
            paginationPage: 1,
        }, this.fetchData)
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    editClient = (item) => {
        this.setState({clientItem: item, addNewClient: true})
    }

    deleteClient = () => {
        const id = this.state.clientItem.ReportID
        if (this.state.clientItem.ArchivedDate === null) {
            this.props.dispatch(deleteResource({
                user: LocalStorage.get('user'),
                query: Object.assign({
                    id: id,
                }, this.getQuery()),
                resource: Resources.Report,
                piggyResource: Resources.Report
            }))
        } else {
            this.props.dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: {
                    ReportID: id,
                    ArchivedDate: 1
                },
                query: this.getQuery(),
                resource: Resources.Report,
                piggyResource: Resources.Report
            }));
        }
        this.hideConfirmDialog()
    }

    toggleArchived = () => this.setState({archived: !this.state.archived})

    showConfirmDialog = item => this.setState({confirmDialog: true, clientItem: item});
    hideConfirmDialog = () => this.setState({confirmDialog: false, clientItem: null});

    showAddNewClient = () => this.setState({addNewClient: true, clientItem: null});
    hideAddNewClient = () => this.setState({addNewClient: false, clientItem: null, quizzStart: false, retake: null});

    returnSortIcons = (value) => {
        if (this.state.sortBy === value) {
            if ((this.state.sort === "DESC")) {
                return (
                    <div className="up-n-down ml-10 color-white">
                        <i className="fas fa-chevron-up opacity-0-4 color-white"></i>
                        <i className="fas fa-chevron-down color-white"></i>
                    </div>
                )
            } else {
                return (
                    <div className="up-n-down ml-10 color-white">
                        <i className="fas fa-chevron-up color-white"></i>
                        <i className="fas fa-chevron-down opacity-0-4 color-white"></i>
                    </div>
                )
            }
        } else {
            return (
                <div className="up-n-down ml-10 color-white">
                    <i className="fas fa-chevron-up color-white"></i>
                    <i className="fas fa-chevron-down color-white"></i>
                </div>
            )
        }
    }

    startQuiz = (item, retake = null) => {
        this.setState({clientItem: item, quizzStart: true, addNewClient: true, retake: retake})
    }

    hideQuizzStartConfirmDialog = (item) => {
        this.setState({clientItem: null, quizzStartDialog: false})
    }

    startQuizHandler = () => {
        this.props.history.push(`/report/start/${this.state.clientItem.ReportID}`);
    }

    groupBy = (arr = [], property) => {
        return Array.isArray(arr) ? arr.reduce(function (memo, x) {
            if (!memo[x[property]]) {
                memo[x[property]] = [];
            }
            memo[x[property]].push(x);
            return memo;
        }, {}) : {}
    }

    handleLinkClick = (e) => this.props.history.push(e);


    showAddNewAssessment = () => this.setState({addNewAssessment: true, clientItem: null});
    hideAddNewAssessment = () => this.setState({addNewAssessment: false, clientItem: null});

    showReportHistory = elem => {
        this.setState({showHistoryDialog: true, ReportID: elem.ReportID})
    }
    hideReportHistory = () => this.setState({showHistoryDialog: false, ReportID: null});

    copyReportGroup = (fields) => {
        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            params: Object.assign({}, FieldsManager.getFieldKeyValues(fields), {
                ReportGroupID: fields.ReportGroupID.value !== "-1" ? fields.ReportGroupID.value.value : "-1",
                ClientID: this.props.match.params.id
            }),
            resource: Resources.ReportGroupsCopy,
            query: this.getQuery(),
            piggyResource: Resources.Report
        }));
    }

    getHeaders = () => {
        return ['ReportGroupName', 'SetLevel', 'Operant', 'ReportMilestone', 'AssessmentType', 'Status', 'LastScore', 'LastReportDate']
    }

    getAction = (item, classes = "") => {
        return (
            <button
                title={item.title}
                type="button"
                onClick={item.onClick}
                className={classNames(classes, "h-11 sm:h-9 inline-flex items-center px-4 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-inverse hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500")}
            >
                {item.icon}
                <span className="hidden md:inline">{item.label}</span>
            </button>
        )
    }

    getActions = (it, containerClass = "", buttonClasses = "") => {
        const translate = this.props.translate;
        return (
            <td className={containerClass}>
                {it.ReportStatusID == 1 && checkPerm(Resources.Report, UPDATE_PERM) && this.getAction({
                    title: "Start",
                    onClick: () => this.startQuiz(it),
                    icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                               stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"/>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>,
                    label: "Start"
                }, buttonClasses)}

                {it.ReportStatusID == 2 && checkPerm(Resources.Report, UPDATE_PERM) && this.getAction({
                    title: "Resume",
                    onClick: () => this.props.history.push(`/report/start/${it.ReportID}`),
                    icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                               stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>,
                    label: "Resume"
                }, buttonClasses)}

                {it.ReportStatusID == 3 && checkPerm(Resources.Report, UPDATE_PERM) && this.getAction({
                    title: "Retake",
                    onClick: () => this.startQuiz(it, true),
                    icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6"
                               fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="2"
                              d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"/>
                    </svg>,
                    label: "Retake"
                }, buttonClasses)}

                {it.ReportStatusID == 3 && checkPerm(Resources.Report, UPDATE_PERM) && this.getAction({
                    title: "Results",
                    onClick: () => this.showReportHistory(it),
                    icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                               stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"/>
                    </svg>,
                    label: "Results"
                }, buttonClasses)}


                {it.ReportStatusID != 3 && checkPerm(Resources.Report, UPDATE_PERM) && this.getAction({
                    title: "Update",
                    onClick: () => this.editClient(it),
                    icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                               stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                    </svg>,
                    label: "Update"
                }, buttonClasses)}


                {checkPerm(Resources.Report, DELETE_PERM) && this.getAction({
                    title: it.ArchivedDate === null ? translate("text.Delete") : translate("text.Restore"),
                    onClick: () => this.showConfirmDialog(it),
                    icon: it.ArchivedDate === null ?
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                        </svg> :
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"/>
                        </svg>,
                    label: it.ArchivedDate === null ? translate("text.Delete") : translate("text.Restore")
                }, buttonClasses)}
            </td>
        )
    }

    render() {
        const {translate, resource} = this.props;

        const Client = getProp(resource.data, "Client", {})
        const AssessmentType = getLookup('AssessmentType', 'AssessmentTypeID', 'AssessmentType');

        const dataItems = getProp(resource.data, "list", [])
        const GroupByGroupID = this.groupBy(dataItems, "ReportGroupName");
        let levelList = [];
        const data = Object.keys(GroupByGroupID).map((elem) => {
            const LevelGroup = this.groupBy(GroupByGroupID[elem], "SetLevel")

            levelList = Object.keys(LevelGroup);

            return (
                <>
                    <tr className={`border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 h-12 overflow-scroll cursor-pointer ${elem.ArchivedDate === null ? "" : "bg-blue-green-04"}`}>
                        <td
                            className={"border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 h-12"}
                            onClick={() => this.setState({[`group${elem}`]: !this.state[`group${elem}`]})}>
                            {elem} <i className={`fas fa-${!this.state[`group${elem}`] ? "minus" : "plus"}-square`}/>
                        </td>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td className={'w-10 px-4 py-2 text-sm text-white'}>
                            {this.getAction({
                                title: "Copy",
                                onClick: () => this.setState({
                                    ReportCopyIDs: GroupByGroupID[elem],
                                    copyGroupReportDialog: true
                                }),
                                icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                           viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"/>
                                </svg>,
                                label: "Copy"
                            })}
                        </td>
                    </tr>

                    {!this.state[`group${elem}`] && Object.keys(LevelGroup).map(item => {
                        return (
                            <>
                                <tr className={`border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 h-12 overflow-scroll cursor-pointer ${item.ArchivedDate === null ? "" : "bg-blue-green-04"}`}>
                                    <td/>
                                    <td
                                        className='border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 h-12'
                                        onClick={() => this.setState({[`level${item}${elem}`]: !this.state[`level${item}${elem}`]})}>
                                        {item} <i
                                        className={`fas fa-${!this.state[`level${item}${elem}`] ? "minus" : "plus"}-square`}/>
                                    </td>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                {!this.state[`level${item}${elem}`] && LevelGroup[item].map(x => {
                                    return (
                                        <tr className={`overflow-scroll border-secondary-200 border hover:bg-gray-200 p-3 border-b-0 h-12 ${x.ArchivedDate === null ? "" : "bg-blue-green-04"}`}>
                                            <td/>
                                            <td/>
                                            <td className={'border-secondary-200 border  p-3 border-b-0 h-12'}>{x.Operant}</td>
                                            <td className={'border-secondary-200 border  p-3 border-b-0 h-12'}>{x.ReportMilestone}</td>
                                            <td className={'border-secondary-200 border  p-3 border-b-0 h-12'}>{AssessmentType[x.ReportTypeID]}</td>
                                            <td className={'border-secondary-200 border  p-3 border-b-0 h-12'}>{reportStatus[x.ReportStatusID]}</td>
                                            <td className={'border-secondary-200 border  p-3 border-b-0 h-12'}>{x.LastReportObservationalScore}</td>
                                            <td className={'border-secondary-200 border  p-3 border-b-0 h-12'}>{toFrontDateTimeFromUTC(x.LastReportDate)}</td>

                                            {this.getActions(x, "w-10 px-4 py-2 text-sm text-white", "my-1")}
                                        </tr>
                                    )
                                })}

                            </>
                        )
                    })}
                </>
            )
        })

        const columns = this.state.headers
            .map((it) => {
                return (
                    <th
                        className="px-4 py-2 bg-kp-blue-dark text-left text-xs font-medium text-white uppercase tracking-wider"
                    >
                        <div className="flex">
                            <div className="mr-3">
                                {translate("table." + it)}
                            </div>
                        </div>
                    </th>
                )
            });

        return (
            <LayoutDashboard {...this.props}>
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12 relative">
                    <PageHeading
                        title={
                            <h4 className='color-dark-blue'>
                                <GoBackIcon
                                    onClick={() => this.props.history.push("/clients")}
                                />
                                {translate("page.title.Assessments")} - <span>{Client.ClientFirstName} {Client.ClientLastName}</span>
                            </h4>
                        }
                        titleClass="pb-1"
                    >
                        <ArchivedSwitch
                            classNameContainer="h-9 mr-2 mt-1"
                            translate={translate}
                            value={this.state.archived}
                            onChange={this.toggleArchived}
                        />

                        {
                            checkPerm(Resources.Report, CREATE_PERM) && (
                                <button
                                    onClick={this.showAddNewAssessment}
                                    className={"btn btn-primary ml-3 my-1"}>
                                    {translate("btn.add_new_assessment")}
                                </button>
                            )
                        }

                        <button
                            onClick={() => this.handleLinkClick(`/groups/${this.props.match.params.id}`)}
                            className="btn btn-outline ml-3 my-1">
                            {translate("btn.Groups")}
                        </button>

                        {
                            checkPerm(Resources.Report, CREATE_PERM) && (
                                <button
                                    onClick={() => this.handleLinkClick(`/documents/${this.props.match.params.id}`)}
                                    className={"btn btn-outline ml-3 my-1"}>
                                    {translate("btn.documents")}
                                </button>
                            )
                        }
                    </PageHeading>

                    {!this.props.resource.isLoading && (
                        <div className="view mb-35 hidden lg:block">
                            <div
                                className='align-middle min-w-full overflow-x-auto md:shadow overflow-hidden md:rounded-lg relative'>
                                <table className="w-full min-w-full divide-y divide-secondary-200">
                                    <thead>
                                    <tr>
                                        {columns}
                                        <th
                                            className={classNames("px-6 py-3 text-center bg-kp-blue-dark text-xs font-medium text-white uppercase tracking-wider", resource.isLoading ? "w-full" : "w-10")}
                                        >
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="bg-inverse divide-y divide-secondary-200">

                                    {!resource.isLoading && data}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}

                    {this.props.resource.isLoading && (
                        <LoaderSmall/>
                    )}

                    {(dataItems.length === 0) && !resource.isLoading && (
                        <div className="margin-auto text-center mt-10">
                            {translate("text.no_records")}
                        </div>
                    )}
                </div>

                <div className="w-full col-span-12 lg:hidden mt-8">
                    <div className="mb-8">
                        <div className="flex">
                            <div className="w-full">
                                <label className="field-label">Select a group</label>
                                <FieldSelect
                                    name={"ReportGroupName"}
                                    addClass="form-control form-select mb-4 h-11 sm:h-9"
                                    values={Object.keys(GroupByGroupID).reduce((memo, it) => {
                                        memo[it] = it;
                                        return memo
                                    }, {})}
                                    onChange={this.handleInputChange}
                                    {...this.state.fields.ReportGroupName}
                                />
                            </div>

                            {this.state.fields.ReportGroupName.value && (
                                <div className="pt-6">
                                    {this.getAction({
                                        title: "Copy",
                                        onClick: () => {
                                            this.setState({
                                                ReportCopyIDs: GroupByGroupID[this.state.fields.ReportGroupName.value],
                                                copyGroupReportDialog: true
                                            })
                                        },
                                        icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                                   viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"/>
                                        </svg>,
                                        label: "Copy"
                                    }, "ml-2")}
                                </div>
                            )}
                        </div>

                        <label className="field-label">Select a Level</label>
                        <FieldSelect
                            all={"All levels"}
                            name={"SetLevel"}
                            addClass="form-control form-select h-11 sm:h-9"
                            values={levelList.reduce((memo, it) => {
                                memo[it] = it;
                                return memo
                            }, {})}
                            onChange={this.handleInputChange}
                            {...this.state.fields.SetLevel}

                        />
                    </div>
                    {dataItems.filter(it => {
                        // Todo: refactor
                        if (!this.state.fields.SetLevel.value) {
                            if (it.ReportGroupName == this.state.fields.ReportGroupName.value) {
                                return it;
                            }
                        } else {
                            if (it.ReportGroupName == this.state.fields.ReportGroupName.value && it.SetLevel == this.state.fields.SetLevel.value) {
                                return it;
                            }
                        }

                        return null;
                    }).map((it) => {
                        return (
                            <div className="overflow-hidden rounded-lg border mb-9">
                                <table className="sm:bg-white shadow-lg w-full">
                                    {this.state.headers.map((row, rowIndex) => (
                                        <tr>
                                            <td className={"border-secondary-200 bg-secondary-50 p-3 text-left text-secondary-900 border-t border-r font-bold w-32" + (!!rowIndex ? " border-t-0" : "")}>
                                                {row}
                                            </td>

                                            <td className={"border-secondary-200 hover:bg-gray-100 p-3 bg-inverse" + (!!rowIndex ? " border-t" : "")}>
                                                {row === "Status" ? reportStatus[it.ReportStatusID] : it[row]}
                                            </td>
                                        </tr>
                                    ))}

                                    <tr>
                                        <td className={"border-secondary-200 bg-secondary-50 p-3 text-left text-secondary-900 border-t border-r font-bold w-32 border-t-0"}>
                                            Actions
                                        </td>

                                        <td className={"border-secondary-200 hover:bg-gray-100 p-3 bg-inverse border-t"}>
                                            {this.getActions(it, "", "m-1")}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        )
                    })}
                </div>

                {!!this.state.addNewClient && (
                    <ReportDialog
                        close={this.hideAddNewClient}
                        modalTitle={!!this.state.quizzStart ? translate("text.start") : !!this.state.clientItem ? translate("text.update_report") : translate("text.new_report")}
                        query={this.getQuery()}
                        translate={translate}
                        clientItem={this.state.clientItem}
                        retake={this.state?.clientItem?.ReportStatusID == 3}
                        {...this.props}
                        quizzStart={this.state.quizzStart}
                    />
                )}

                <ModalConfirm
                    visible={this.state.confirmDialog}
                    title={translate(this.state.clientItem?.ArchivedDate === null ? "text.delete_report" : "text.restore_report")}
                    text={`${translate(this.state.clientItem?.ArchivedDate === null ? "text.are_you_sure_you_want_to_delete" : "text.are_you_sure_you_want_to_restore")} ${this.state.clientItem?.SetName}?`}
                    translate={translate}
                    onClose={this.hideConfirmDialog}
                    onConfirm={() => this.deleteClient()}
                />

                {!!this.state.quizzStartDialog && (
                    <ConfirmDialog
                        submit={this.startQuizHandler}
                        close={this.hideQuizzStartConfirmDialog}
                        modalTitle={translate("text.confirm")}
                        modalText={`${translate("text.are_you_sure_you_want_to_start_this_set")}?`}
                        translate={translate}
                        item={this.state.clientItem}
                    />
                )}

                {!!this.state.showHistoryDialog && (
                    <ReportHistoryDialog
                        close={this.hideReportHistory}
                        ReportID={this.state.ReportID}
                        translate={translate}
                        history={this.props.history}
                        reportQuery={this.getQuery()}
                    />
                )}

                {!!this.state.addNewAssessment && (
                    <ReportDialog
                        close={this.hideAddNewAssessment}
                        modalTitle={!!this.state.quizzStart ? translate("text.start") : !!this.state.clientItem ? translate("text.update_report") : translate("text.new_report")}
                        query={this.getQuery()}
                        translate={translate}
                        clientItem={this.state.clientItem}
                        {...this.props}
                        quizzStart={this.state.quizzStart}
                    />
                )}

                {!!this.state.copyGroupReportDialog && (
                    <CopyGroupReportDialog
                        ReportCopyIDs={this.state.ReportCopyIDs}
                        close={() => this.setState({ReportCopyIDs: [], copyGroupReportDialog: false})}
                        translate={translate}
                        ClientID={this.props.match.params.id}
                        submit={this.copyReportGroup}
                        groupExist={this.state.groupExist}
                    />
                )}
            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(ReportView);
